import { onDomReady } from '../../components/dynamic/observer';
import Jax from '../../components/jax/jax';

class SelectCityForm {
  constructor(element) {
    this.element = element;
    this.form = element.querySelector('form');
    this.inputs = element.querySelectorAll('input[type="radio"]');
    this.bind();
  }

  bind() {
    const instance = this;
    this.inputs.forEach((input) => input.addEventListener('change', instance.submit));
  }

  submit() {
    let jax = new Jax(this.form.getAttribute('action'), 'POST', true);
    jax.send(new FormData(this.form)).then((json) => {
      document.location.reload();
    });

  }
}
function init() {
  document.querySelectorAll('[data-select-city-form]')
    .forEach((element) => new SelectCityForm(element));
}

onDomReady(init);
