import {onDomReady} from "../../components/dynamic/observer";
import {EffectCreative, Navigation, Pagination, Swiper, Autoplay} from 'swiper';
// import '../../../node_modules/swiper/swiper.scss';
import '../../../node_modules/swiper/swiper-bundle.min.css';

function init(sliderElement)
{
    if (!sliderElement) {
        return;
    }

    const device = sliderElement.dataset.heroSlider;

    const leftArrow = document.querySelector(`[data-hero-slider-${device}-left]`);
    const rightArrow = document.querySelector(`[data-hero-slider-${device}-right]`);
    const pagination = document.querySelector(`[data-hero-slider-${device}-pagination]`);

    const slider = new Swiper(sliderElement, {
        modules: [Navigation, EffectCreative, Pagination, Autoplay],
        slidesPerView: 1,
        speed: 700,
        loop: true,
        grabCursor: true,
        preventInteractionOnTransition: true,
        effect: "creative",
        pagination:{
            el: pagination,
            dynamicBullets: false,
        },
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
            waitForTransition: false
        },
        navigation: {
            prevEl: leftArrow,
            nextEl: rightArrow,
        },
        creativeEffect: {
            prev: {
                translate: ["-70%", 0, -1],
            },
            current: {
                translate: [0, 0, 1],
            },
            next: {
                translate: ["100%", 0, 2],
            },
        },

    });
}
onDomReady(() => {
    const swipeSliderElements = document.querySelectorAll('[data-hero-slider]');

    swipeSliderElements.forEach((slider) => {
        if (slider) {
            init(slider);
        }
    })
})