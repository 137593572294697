require('./scroll-slider');
require('./swipe-slider');
require('./hero-slider');
require('./images-slider');
require('./variants-slider');
require('./completed-slider');
require('./rslider');
require('./index-slider');
require('./partners-slider');
require('./partners-products-slider');
require('./index-reviews-slider');
require('./equipment-slider');
require('./history-slider');