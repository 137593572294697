<template>

    <div class="checkbox-list" :class="blockClasses">
        <label class="checkbox-list__label text text__caption-big"
               v-if="field.label"
               :class="elementClasses('label')"
        >
          {{ field.label }}
        </label>

        <div class="checkbox-list__wrap tabs"
             :class="elementClasses('wrapper')"
             v-if="valueItem.value"
        >
            <div class="checkbox-list__values-list tabs__list" :class="elementClasses('values')">
              <div class="checkbox-list__value-item tabs__item "
                   :class="{'active': isAllActive}"
              >
                <input class="checkbox-list__input"
                       type="checkbox"
                       :id="`all_${field.key}`"
                       @click="reset"
                       :value="0"
                       v-model="valueItem.value"
                >

                <label class="checkbox-list__input-label tabs__link filter-field__label"
                       :for="`all_${field.key}`"
                >
                      <span class="checkbox-list__label-text text__caption-big" >
                        Все
                      </span>
                </label>
              </div>
                <div class="checkbox-list__value-item tabs__item"
                     :class="elementClasses('value')"
                     v-for="(choice, index) in field.choices"
                >
                    <input class="checkbox-list__input"
                           type="checkbox"
                           :id="id(choice, index)"
                           :value="choice.key"
                           v-model="valueItem.value"
                           :disabled="choice.disabled || field.disabled"
                    >

                    <label class="checkbox-list__input-label tabs__link"
                           :for="id(choice, index)"
                           :class="elementClasses('label')"
                    >
                      <span class="checkbox-list__label-text text__caption-big">
                        {{ choice.label }}
                      </span>
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { fieldMixin } from "./fieldMixin";

export default {
  name: "CheckboxList",
  mixins: [
    fieldMixin
  ],
  computed: {
    isAllActive() {
      return !this.valueItem.value || this.valueItem.value.length === 0;
    }
  },
  methods: {
    checkValue() {
      if (!this.valueItem.value) {
        this.valueItem.value = [];
      }
    },
    id(choice, index) {
      index = index | 0;
      return `${this.field.key}_${choice.key}_${index}`;
    },
    reset() {
      this.valueItem.value = []
    }
  },
  mounted() {
    this.checkValue();
  },
  beforeUpdate() {
    this.checkValue();
  }
}
</script>

<style lang="scss">
.checkbox-list__input-label{
  &:hover{
    cursor: pointer;
    background-color: var(--accent-brown);
    color: var(--base-white-100);
  }
}
.checkbox-list__value-item.active {
  label{
    background-color: var(--accent-brown);
    color: var(--base-white-100);
  }
}
</style>