/**
 * Слушать событие готовности DOM-дерева после загрузки
 *
 * @param callback
 */
function onDomReady(callback) {
    const timeoutDelay = process.env.NODE_ENV !== 'production' ? 2000 : 0;
    setTimeout(() => {
        if (document.readyState === "complete"
            || document.readyState === "loaded"
            || document.readyState === "interactive") {
            callback();
        } else {
            document.addEventListener('DOMContentLoaded', callback);
        }
    }, timeoutDelay);
}

/**
 * Слушать событие готовности DOM-дерева после загрузки или изменений
 *
 * @param callback
 */
function onDomChanges(callback) {
    const timeoutDelay = process.env.NODE_ENV !== 'production' ? 2000 : 0;
    setTimeout(() => {
        if (document.readyState === "complete"
            || document.readyState === "loaded"
            || document.readyState === "interactive") {
            callback();
        } else {
            document.addEventListener('DOMContentLoaded', callback);
        }
        document.addEventListener('DOMContentMutated', callback);
    }, timeoutDelay);
}

export {onDomReady, onDomChanges};