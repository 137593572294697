import {onDomReady} from '../../components/dynamic/observer';

class OpenText {
    constructor(containers)
    {
        this.containers = containers;
        this.init();
    }

    init()
    {
        this.containers.forEach(container => {
            const text = container.querySelector('[data-text-container-content]');
            const opener = container.querySelector('[data-text-container-open]');

            const minLin = Number(text.getAttribute('data-text-container-content'));
            const minHeight = Number(container.getAttribute('data-text-container'));

            if (text && opener) {
                this.handleHiddenButton(text, opener, minLin, minHeight);
                this.setupOpenToggle(text, opener, minLin, minHeight);
            }
        });
    }

    handleHiddenButton(text, opener, minLin, minHeight)
    {
        const updateButtonVisibility = () => {
            text.style.webkitLineClamp = `${minLin}`
            text.scrollHeight < minHeight ? opener.style.display = 'none' : '';
        }
        updateButtonVisibility();

        window.addEventListener('resize', updateButtonVisibility);
    }

    setupOpenToggle(text, opener, minLin, minHeight)
    {
        opener.addEventListener('click', () => {
            const isOpen = text.classList.toggle('open');
            opener.classList.toggle('open', isOpen);

            if (isOpen) {
                text.style.maxHeight = `${text.scrollHeight}px`;
                text.style.webkitLineClamp = 'unset';
            } else {
                text.style.maxHeight = `${minHeight}px`;
                setTimeout(() => text.style.webkitLineClamp = `${minLin}`, 400);
            }
        });
    }
}

onDomReady(() => {
    const containers = document.querySelectorAll('[data-text-container]');
    if (containers.length) {
        new OpenText(containers);
    }
});
