<template>
    <div :class="blockClasses">
        <div :class="elementClasses('fields')">
            <div :class="[...elementClasses('field'), ...elementClasses('field_' + field.key)]" v-for="field in fields">
                <Field v-if="field.visible" :fieldKey="field.key" :filterKey="filterKey" @change="debouncedChange"/>
            </div>

          <div class="filter-fieldset__clear-wrap">
            <a href="javascript:void(0);"
               class="default-fieldset__clear text__caption-little-link"
               title="Сбросить фильтры"
               @click="clear()"
            >
              Сбросить фильтры
            </a>
          </div>
        </div>
    </div>
</template>

<script>
import {fieldsetMixin} from "./fieldsetMixin";
import Field from "../field/Field";
import { FilterManagerPool } from "../manager";
import { debounce } from "lodash";

export default {
  name: "DefaultFieldset",
  components: {Field},
  mixins: [
    fieldsetMixin
  ],
  methods: {
    change() {
      this.manager.submit();
    },
    clear() {
      this.manager.submit(true);
    },
    debouncedChange: debounce(function(e) {
      if (e.target.checked) {
        this.manager.increaseCounter();
      } else {
        this.manager.decreaseCounter();
      }

      this.change();
    }, 300),
  },
  mounted() {
    this.manager = FilterManagerPool.getManager(this.filterKey);
  },
}
</script>

<style lang="scss">

</style>