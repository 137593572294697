import LiveEvent from "../live/live";
import {onDomReady} from "../dynamic/observer";

function smartTab(e) {

    let button = this;
    if (button.tagName === 'A') {
        e.preventDefault();
    }

    let selector = button.dataset.tab; // id таба
    if (!selector && button.getAttribute('href')) {
        selector = button.getAttribute('href');
    }

    let container = button.closest('[data-tabs-container]');

    let tabContents = container.querySelectorAll(selector);
    if (tabContents) {
        container.querySelectorAll(".smart-content").forEach((item) => {
            item.classList.remove('_active')
        });
        tabContents.forEach((tab) => {
            //  отображаемый контент таба
            tab.classList.add('_active');

            window.dispatchEvent(new Event('reloadTextWrapElements'));
        })
    }

    let tabs = button.closest('[data-smart-tabs-multiply]');
    if (!tabs) {
        tabs = button.closest('[data-smart-tabs]');
    }
    tabs.querySelectorAll('li').forEach((item) => {
        item.classList.remove('_active')
    });
    button.closest('li').classList.add('_active');

    let event = new CustomEvent('smart-tabs-handle', {'detail': {'button': button, 'tabs': tabContents}});
    document.dispatchEvent(event);
    document.dispatchEvent(new Event('reloadTextWrapElements'));
}

new LiveEvent('click', '[data-smart-tabs-multiply] li a', smartTab);

onDomReady(() => {
    document.querySelectorAll('[data-smart-tabs-multiply]').forEach((tabsContainer) => {
        let activeTab = tabsContainer.querySelector('li._active a');
        if (activeTab) {
            smartTab.call(activeTab, new Event('DOMContentLoaded'))
        } else {
            let firstTab = tabsContainer.querySelector('li a');
            smartTab.call(firstTab, new Event('DOMContentLoaded'))
        }
    })
})