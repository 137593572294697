import {onDomReady} from "../../components/dynamic/observer";
import {Navigation, Swiper} from "swiper";

function init() {
    const sliderEl = document.querySelector('[data-history-slider]');
    const pagination = document.querySelectorAll('[data-history-slider-pagination]');
    if (!sliderEl) {
        return;
    }
    const slider = new Swiper(sliderEl, {
        modules: [Navigation],
        speed: 700,
        slidesPerView: "auto",
        navigation: {
            prevEl: document.querySelector('[data-history-slider-prev]'),
            nextEl: document.querySelector('[data-history-slider-next]'),
        },
        breakpoints: {
            0: {
                spaceBetween: 18
            },
            768: {
                spaceBetween: 30
            },
            1180: {
                spaceBetween: 40
            }
        },
        on: {
            touchMove: function (swiper, event) {
                const customEvent = new CustomEvent('cursorMove', {
                    detail: {
                        offsetX: event.x,
                        offsetY: event.y,
                    },
                });
                window.dispatchEvent(customEvent);
            },
        },
    });

    slider.on('slideNextTransitionStart', () => {
        togglePagination({pagination, index: slider.realIndex})
    });

    slider.on('slidePrevTransitionStart', () => {
        togglePagination({pagination, index: slider.realIndex})
    });

    if (pagination) {
        pagination[0].classList.add('_active');
        pagination.forEach((item) => {
            const paginationIndex = parseInt(item.dataset.historySliderPagination, 10)
            item.addEventListener('click', () => {
                slider.slideTo(paginationIndex);
            })
        });
    }
}

function togglePagination(data) {
    data.pagination.forEach((item) => {
        if (item.classList.contains('_active')) {
            item.classList.remove('_active');
        }
    })

    data.pagination[data.index].classList.add('_active');
}

onDomReady(init);