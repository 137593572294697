// import {onDomReady} from "../../components/dynamic/observer";
// import {Autoplay, Navigation, Pagination, Parallax, Swiper} from 'swiper';
import RSlider from "../../components/rslider/rslider";
//
// function init()
// {
//     const container = document.querySelector('[data-index-slider-container]');
//     if (!container) {
//         return;
//     }
//
//     const element = document.querySelector('[data-index-slider]');
//     if (!element) {
//         return;
//     }
//
//     const prevElement = container.querySelector('[data-index-slider-prev]');
//     const nextElement = container.querySelector('[data-index-slider-next]');
//     const paginationElement = container.querySelector('[data-index-slider-pagination]');
//
//     const slider = new Swiper(element, {
//         modules: [Navigation, Pagination, Autoplay, Parallax],
//         slidesPerView: 1,
//         // speed: 1000,
//         // parallax: true,
//         // loop: true,
//         // grabCursor: true,
//         // preventInteractionOnTransition: true,
//         // pagination:{
//         //     el: paginationElement,
//         //     dynamicBullets: false,
//         // },
//         // autoplay: {
//         //     delay: 5000,
//         //     disableOnInteraction: false,
//         //     waitForTransition: false
//         // },
//         navigation: {
//             prevEl: prevElement,
//             nextEl: nextElement,
//         },
//     });
//
//     // const textElement = document.querySelector('[data-rslider="index-text-slider"]');
//     // if (!textElement) {
//     //     console.log('Could not find any element with data-index-text-slider');
//     //     return;
//     // }
//     // const textSlider = new RSlider(
//     //     textElement,
//     //     {
//     //         onStart: ({ slideIn, slideFriendsIn }) => {
//     //             slideFriendsIn.forEach((friendIn) => {
//     //                 friendIn.classList.add('_active');
//     //             })
//     //             slideIn.classList.add('_active');
//     //         },
//     //         timeline: [
//     //             RSlider.action({
//     //                 // К слайдам докидывается:
//     //                 // К появляющемуся: --rslider-first-act-in-progress: 0.2;
//     //                 // К исчезающему: --rslider-first-act-out-progress: 0.2;
//     //                 // easing ниже настраивается
//     //                 name: 'next-act',
//     //                 duration: 1000,
//     //                 delay: 0,
//     //                 // easeInOutCubic прогресс для "приходящего" слайда
//     //                 // easingIn: (x) => x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2,
//     //                 // linear прогресс для "уходящего" слайда
//     //                 // easingOut: (x) => x,
//     //                 onStart: ({ slideIn, slideOut, slideFriendsIn, slideFriendsOut }) => {
//     //                     slideIn.classList.add('_active');
//     //                     slideOut.classList.remove('_active');
//     //
//     //                     slideIn.classList.add('_in-next-act');
//     //                     slideOut.classList.add('_out-next-act');
//     //
//     //                     slideFriendsIn.forEach((friendIn) => {
//     //                         friendIn.classList.add('_active');
//     //                         friendIn.classList.add('_in-next-act');
//     //                     })
//     //
//     //                     setTimeout(() => {
//     //                         slideIn.classList.remove('_in-next-act');
//     //
//     //                         slideFriendsIn.forEach((friendIn) => {
//     //                             friendIn.classList.remove('_in-next-act');
//     //                         })
//     //                     }, 500);
//     //
//     //                     slideFriendsOut.forEach((friendOut) => {
//     //                         friendOut.classList.remove('_active');
//     //                         friendOut.classList.add('_out-next-act');
//     //                     })
//     //                 },
//     //                 onEnd: ({ slideIn, slideOut, slideFriendsIn, slideFriendsOut }) => {
//     //                     slideOut.classList.remove('_out-next-act');
//     //
//     //                     slideFriendsOut.forEach((friendOut) => {
//     //                         friendOut.classList.remove('_out-next-act');
//     //                     })
//     //                 }
//     //             }),
//     //         ],
//     //     }
//     // );
//
//     slider.on('slideNextTransitionStart', () => {
//         // textSlider.next();
//         // console.log('next');
//     });
//
//     slider.on('slidePrevTransitionStart', () => {
//         // textSlider.prev();
//         // console.log('prev');
//     });
// }
//
// onDomReady(init);

import {onDomReady} from "../../components/dynamic/observer";
import {EffectCreative, Navigation, Pagination, Swiper, Autoplay} from 'swiper';
// import '../../../node_modules/swiper/swiper.scss';
import '../../../node_modules/swiper/swiper-bundle.min.css';

function init()
{
    const swipeSliderElement = document.querySelector('[data-index-slider]');
    if (!swipeSliderElement) {
        return;
    }

    const leftArrow = document.querySelector('[data-index-slider-left]');
    const rightArrow = document.querySelector('[data-index-slider-right]');
    const pagination = document.querySelector('[data-index-slider-pagination]');

    const count = swipeSliderElement.querySelectorAll('[data-hero-translatable] > li').length;
    const slider = new Swiper(swipeSliderElement, {
        modules: [Navigation, EffectCreative, Pagination, Autoplay],
        slidesPerView: 1,
        speed: 1000,
        loop: count > 1,
        grabCursor: true,
        preventInteractionOnTransition: true,
        effect: "creative",
        pagination:{
            el: pagination,
            dynamicBullets: false,
        },
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
            waitForTransition: false
        },
        navigation:{
            prevEl: leftArrow,
            nextEl: rightArrow,
        },
        creativeEffect: {
            prev: {
                translate: ["-28%", 0, -1],
            },
            current: {
                translate: [0, 0, 1],
            },
            next: {
                translate: ["100%", 0, 2],
            },
        },

    });

    const textElement = document.querySelector('[data-rslider="index-text-slider"]');
    if (!textElement) {
        console.log('Could not find any element with data-index-text-slider');
        return;
    }
    const textSlider = new RSlider(
        textElement,
        {
            onStart: ({ slideIn, slideFriendsIn }) => {
                slideFriendsIn.forEach((friendIn) => {
                    friendIn.classList.add('_active');
                })
                slideIn.classList.add('_active');
            },
            timeline: [
                RSlider.action({
                    // К слайдам докидывается:
                    // К появляющемуся: --rslider-first-act-in-progress: 0.2;
                    // К исчезающему: --rslider-first-act-out-progress: 0.2;
                    // easing ниже настраивается
                    name: 'next-act',
                    duration: 1000,
                    delay: 0,
                    // easeInOutCubic прогресс для "приходящего" слайда
                    // easingIn: (x) => x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2,
                    // linear прогресс для "уходящего" слайда
                    // easingOut: (x) => x,
                    onStart: ({ slideIn, slideOut, slideFriendsIn, slideFriendsOut }) => {
                        slideIn.classList.add('_active');
                        slideOut.classList.remove('_active');

                        slideIn.classList.add('_in-next-act');
                        slideOut.classList.add('_out-next-act');

                        slideFriendsIn.forEach((friendIn) => {
                            friendIn.classList.add('_active');
                            friendIn.classList.add('_in-next-act');
                        })

                        setTimeout(() => {
                            slideIn.classList.remove('_in-next-act');

                            slideFriendsIn.forEach((friendIn) => {
                                friendIn.classList.remove('_in-next-act');
                            })
                        }, 500);

                        slideFriendsOut.forEach((friendOut) => {
                            friendOut.classList.remove('_active');
                            friendOut.classList.add('_out-next-act');
                        })
                    },
                    onEnd: ({ slideIn, slideOut, slideFriendsIn, slideFriendsOut }) => {
                        slideOut.classList.remove('_out-next-act');

                        slideFriendsOut.forEach((friendOut) => {
                            friendOut.classList.remove('_out-next-act');
                        })
                    }
                }),
            ],
            swipe: false,
        }
    );

    slider.on('slideNextTransitionStart', () => {
        textSlider.next();
    });

    slider.on('slidePrevTransitionStart', () => {
        textSlider.prev();
    });
}

onDomReady(init);