import {onDomReady} from '../../components/dynamic/observer';
import ScaledImageObject from "./object/ScaledImageObject";

class Scaled {
    constructor(container)
    {
        this.container = container;
        this.isMobile = window.innerWidth < 1180;
        this.offsetY = 0;
        this.scaledObject = undefined;
        this.initObjects();
        this.eventListeners();
    }


    initObjects()
    {
        this.initScaledObject();
    }

    initScaledObject()
    {
        this.scaledObject = new ScaledImageObject(this.container);
        this.scaledObject.clearStyles();
    }

    destroyObjects()
    {
        this.scaledObject.clearStyles();
        this.scaledObject = null;
    }

    eventListeners()
    {
        document.addEventListener('scroll', () => {
            this.offsetY = window.scrollY;
            if (this.scaledObject) {
                this.scaledObject.setStyles();
                this.scaledObject.update(this.offsetY, this.container);
            }
        });

        window.addEventListener('resize', () => {
            if (!this.isMobile) {
                this.initObjects();
            } else {
                this.destroyObjects();
            }
        });
    }
}

onDomReady(() => {
    if (window.innerWidth < 1180) {
        return;
    }

    const scaledContainer = document.querySelector('[data-scaled]');

    if (!scaledContainer) {
        return;
    }

    new Scaled(scaledContainer);
});