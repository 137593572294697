import {onDomReady} from "../../components/dynamic/observer";

function init()
{
    const select = document.querySelector('#variant-select');
    const variants = document.querySelector('[data-variant-select]');
    const linkContainer = document.querySelector('[data-link-container]');
    if (!select || !variants || !linkContainer) {
        return;
    }

    select.addEventListener('change', (event) => {
        const selectedOption = event.target.value;

        variants.querySelectorAll('[data-variant]').forEach(variant =>{
            variant.classList.remove('_active');
        });
        variants.querySelector(`[data-variant-id="${selectedOption}"]`).classList.add('_active');

        linkContainer.querySelectorAll('[data-link]').forEach(link =>{
            link.classList.remove('_active');
        });
        linkContainer.querySelector(`[data-link-id="${selectedOption}"]`).classList.add('_active');
    })
}

onDomReady(init);