import {onDomChanges} from "../../components/dynamic/observer";

class HeightChange {
    container = undefined;
    isMobile = window.innerWidth < 1180;
    constructor()
    {
        this.containers = document.querySelectorAll('[data-height-container]');

        this.containers.forEach((item) => {
            this.init(item);
        });

        this.eventListeners();
    }

    init(item)
    {
        const target = item.querySelector('[data-height-target]');

        if (!target) {
            return;
        }

        const content = target.querySelector('[data-height-content]');
        const targetTitle = item.querySelector('[data-height-target-title]');

        let height = content.getBoundingClientRect().height;

        if (height === 0) {
            return;
        }

        if (!this.isMobile && target.dataset.dataHeigthTarget !== 'true') {
            target.style.height = 0 + 'px';
            target.style.opacity = '0';
        }


        // update height on resize
        window.addEventListener('resize', () => {
            height = content.getBoundingClientRect().height;
        });

        item.addEventListener('mouseover', () => {
            target.style.height = height + 'px';
            target.style.opacity = '1';
            targetTitle.style.transform = `translateY(0px)`;
        });

        item.addEventListener('mouseleave', () => {
            target.style.height = 0 + 'px';
            target.style.opacity = '0';
            setTimeout(() => {
                targetTitle.style.transform = `translateY(0px)`;
            }, 300);
        });

    }
    eventListeners()
    {
        // TODO: write reload items
        // window.addEventListener('resize', () => {
        //     this.containers.forEach((item) => {
        //         this.init(item);
        //     });
        // });
    }
}
function init()
{
    const heights = new HeightChange();
}

onDomChanges(() => init());