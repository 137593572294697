import {onDomReady} from '../../components/dynamic/observer';

class Header {
    constructor(selector) {
        this.header = selector;
        this.headerHeight = this.header.offsetHeight;

        this.hamburger = selector.querySelector('[data-hamburger]');
        this.dropMenu = selector.querySelector('[data-drop-menu]');
        this.hoverClosers = document.querySelectorAll('[data-hover-closer]');
        this.cityChoice = undefined;

        this.footer = document.querySelector('[data-footer]');

        this.isOpened = false;
        this.scrollTop = 0;

        this.layout = document.querySelector('.layout');

        this.eventListeners();
    }

    open() {
        this.toggleOpened();
        setTimeout(() => {
            this.isOpened = true;
        }, 200);

        document.body.style.overflow = 'hidden';
        document.body.style.height = '100%';
        document.dispatchEvent(new Event('drop-menu-open'));
    }

    close() {
        this.toggleOpened();
        this.isOpened = false;

        document.body.style.overflow = '';
        document.body.style.height = '';
        document.dispatchEvent(new Event('drop-menu-close'));
    }

    toggleOpened() {
        this.header.classList.toggle('_opened');
        this.dropMenu.classList.toggle('_opened');
        this.hamburger.classList.toggle('_opened');
    }

    handleHeader() {
        const instance = this;

        if (instance.scrollTop > instance.headerHeight) {
            instance.header.classList.add('_sticky');

            if (this.cityChoice) {
                instance.cityChoice.classList.add('_sticky', '_hide-bg');
            }
        } else {
            instance.header.classList.remove('_sticky', '_up');

            if (this.cityChoice) {
                instance.cityChoice.classList.remove('_sticky', '_up');
            }
        }

        if (instance.scrollTop < instance.lastScrollTop && instance.scrollTop > 0) {
            // scroll up
            instance.header.classList.add('_up');

            if (this.cityChoice) {
                instance.cityChoice.classList.add('_up');
            }
        } else {
            // scroll down
            instance.header.classList.remove('_up');

            if (this.cityChoice) {
                instance.cityChoice.classList.remove('_up');
            }
        }
    }

    eventListeners() {
        document.addEventListener('click', (e) => {
            if (this.isOpened && e.target === this.layout) {
                if (e.target) {
                    this.close();
                }
            }
        });

        if (this.hamburger) {
            this.hamburger.addEventListener('click', () => {
                this.isOpened ? this.close() : this.open();
            });
        }

        if (!this.cityChoice) {
            document.addEventListener('CityCoverOpen', () => {
                this.cityChoice = document.querySelector('[data-city-cover]');
            });
        }

        if (this.hoverClosers) {
            this.hoverClosers.forEach((element) => {
                element.addEventListener('mouseenter', () => {
                    if (this.isOpened) {
                        this.close();
                    }
                });
            });
        }

        window.addEventListener('scroll', () => {
            const instance = this;
            instance.scrollTop = window.scrollY;
            instance.handleHeader();
            instance.lastScrollTop = instance.scrollTop;
        });
    }
}

onDomReady(() => {
    const headerSelector = document.querySelector('[data-header]');
    if (headerSelector) {
        new Header(headerSelector);
    }
});