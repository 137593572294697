import LiveEvent from "../../components/live/live";
import {onDomReady} from "../../components/dynamic/observer";

function addUnderlinedContainers(containers)
{
    containers.forEach((container) => {
        const target = container.querySelector('[data-underline-text-target]');
        container.addEventListener('mouseenter', () => {
            target.classList.add('underline');
        });
    container.addEventListener('mouseleave', () => {
        target.classList.remove('underline');
      });
    });
}

function addUnderlined(targets, data = null)
{
    targets.forEach((item) => {
        if (data && data.city) {
            defineLineWidth(item);
        }

        item.addEventListener('mouseenter', () => {
            item.classList.add('underline');
          // if (!item.classList.contains('hovered')) {
          //     item.classList.add('underline');
          //     item.classList.add('hovered');
          //     setTimeout(() => {
          //         item.classList.remove('hovered');
          //     }, 350);
          // }
        });

    item.addEventListener('mouseleave', () => {
        item.classList.remove('underline');
      // if (!item.classList.contains('hovered')) {
      //     item.classList.remove('underline');
      // } else {
      //     setTimeout(() => {
      //         item.classList.remove('hovered');
      //     }, 350);
      // }
      });
    });
}

function defineLineWidth(item)
{
  // find gap and checked mark width to find correct text width
    const columnGap = parseInt(window.getComputedStyle(item).columnGap, 10);
    const afterElementWidth = parseInt(window.getComputedStyle(item, ':after').width, 10);

  // find text width and calculate underline width
    item.style.width = 'fit-content';
    const itemWidth = parseInt(window.getComputedStyle(item).width, 10);
    const lineWidth = itemWidth - afterElementWidth - columnGap;

  // create variable for ::after element
  // and return item to initial state
    item.style.setProperty('--underline-width', `${lineWidth}px`);
    item.style.width = '100%';
}

function initializeUnderlineEffects() {
    const underlineTextElements = document.querySelectorAll('[data-underline-text]');
    const underlineTextContainers = document.querySelectorAll('[data-underline-text-container]');
    const cityFieldsWrap = document.querySelectorAll('[data-city-label-underline]');

    addUnderlined(underlineTextElements);
    addUnderlinedContainers(underlineTextContainers);

    cityFieldsWrap.forEach((cityField) => {
        const labels = cityField.querySelectorAll('label');
        if (labels.length > 0) {
            addUnderlined(labels, { city: true });
        }
    });
}

onDomReady(initializeUnderlineEffects);


document.addEventListener('DOMContentMutated', initializeUnderlineEffects);
document.addEventListener('CityCoverOpen', () => {
    const cityFieldsWrap = document.querySelectorAll('[data-city-label-underline]');

    cityFieldsWrap.forEach((cityField) => {
        const labels = cityField.querySelectorAll('label');
        if (labels.length > 0) {
            addUnderlined(labels, { city: true });
        }
    });
});