<template>
  <a href="javascript:void(0);"
     class="project-filter__link"
     data-accordion-link
     title="Фильтры"
  >
    <span class="project-filter__link-icon">
      <FilterIcon/>
    </span>

    <span class="project-filter__link-title-wrap">
      <span class="project-filter__link-title text text__label">
          Фильтры
      </span>
      <span class="project-filter__link-counter" v-if="counter">
        {{ counter }}
      </span>
    </span>
  </a>
</template>

<script>
  import FilterIcon from "../icons/FilterIcon.vue";
  import {mapState} from "pinia";
  import {useFilterStore} from "../../stores/filter";

  export default {
    name: "TheFilterMobLink",
    components: {FilterIcon},
    computed: {
      ...mapState(useFilterStore, [
        'counter'
      ])
    },
  };
</script>