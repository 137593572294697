import {onDomReady} from "../../components/dynamic/observer";

class ArticleTitle {
    constructor(list) {
        this.list = list;
        this.items = [];
        this.desktopRows = [];
        this.tabRows = [];
        this.isSameRowItems = this.list.dataset.sameRowItems ? this.list.dataset.sameRowItems : false;
        this.init();
    }

    init() {
        this.initItems();
        this.initEvents();
        this.initListRows();
        this.initTitleHeightByRow();
    }

    isTab() {
        return window.innerWidth > 767 && window.innerWidth < 1180;
    }

    isDesktop() {
        return window.innerWidth > 1179;
    }

    initItems() {
        this.items = this.list.querySelectorAll('[data-article-item]');
    }

    initEvents() {
        window.addEventListener('resize', () => {
            this.initListRows();
        });
    }

    initListRows() {
        if (this.isTab()) {
            this.initTabRows();
        }

        if (this.isDesktop()) {
            this.initDesktopRows();
        }
    }

    initTabRows() {
        let isBigItem = true;

        if (this.isSameRowItems) {
            isBigItem = false;
        }

        let rowCounter = 0;
        let rows = null;
        const row = [];

        const array = Array.from(this.items);

        while (array.length) {
            if (isBigItem) {
                row.push(array.splice(0, 1));

                if (!this.isSameRowItems) isBigItem = false;

                rowCounter++;
            } else {
                row.push(array.splice(0, 2));
                rowCounter++;
            }

            if (rowCounter === 5) {
                rowCounter = 0;
                if (!this.isSameRowItems) isBigItem = true;
            }
        }

        rows = row;

        this.tabRows = rows;
    }

    initDesktopRows() {
        let isRowWithThreeElements = true;

        if (this.isSameRowItems) {
            isRowWithThreeElements = false;
        }

        let rows = null;
        const row = [];

        const array = Array.from(this.items);

        while (array.length) {
            if (isRowWithThreeElements) {
                row.push(array.splice(0, 3));
                if (!this.isSameRowItems) isRowWithThreeElements = false;
            } else {
                row.push(array.splice(0, 4));
                if (!this.isSameRowItems) isRowWithThreeElements = true;
            }
        }

        rows = row;

        this.desktopRows = rows;
    }

    initTitleHeightByRow() {
        let rows = [];

        if (this.isTab()) {
            rows = this.tabRows;
        }

        if (this.isDesktop()) {
            rows = this.desktopRows;
        }

        rows.forEach((row) => {
            let maxTitleHeight = 0;

            // define max title height
            row.forEach((item) => {
                const title = item.querySelector('[data-article-item-title]');
                const titleHeight = title.offsetHeight;

                if (titleHeight > maxTitleHeight) {
                    maxTitleHeight = titleHeight;
                }
            });

            // set max title height
            row.forEach((item) => {
                const title = item.querySelector('[data-article-item-title]');
                title.style.height = maxTitleHeight + 'px';
            });
        });

    }
}

onDomReady(() => {
    const list = document.querySelector('[data-article-list]');

    if (!list) {
        return;
    }

    new ArticleTitle(list);
});