import {onDomReady} from "../../components/dynamic/observer";
import Slides from "./objects/slides";
import ScrollContent from "./objects/scroll-content";

class AboutScroll {
    constructor(aboutScrollContainer) {
        this.container = aboutScrollContainer;
        this.contentList = this.container.querySelector('[data-about-scroll-list]');
        this.sidebar = this.container.querySelector('[data-about-scroll-sidebar]');
        this.sidebarList = this.container.querySelector('[data-about-scroll-sidebar-list]');
        this.slides = null;
        this.content = null;
        this.currentContentItemId = null;
        this.init();
    }

    init() {
        this.content = new ScrollContent(this.contentList);
        this.addEvents();
        this.slides = new Slides(this.sidebarList);
    }

    addEvents() {
        document.addEventListener('active-scroll-item', (e) => {
            this.currentContentItemId = e.detail.item.dataset.aboutScrollItem;
            this.activeSlide(this.currentContentItemId);
        });
    }

    activeSlide() {
        this.slides.activeSlide(this.currentContentItemId);
    }
}

onDomReady(() => {
    if (window.innerWidth < 1180) {
        return;
    }

    const aboutScrollContainer = document.querySelector('[data-about-scroll-container]')

    if (!aboutScrollContainer) {
        return;
    }

    new AboutScroll(aboutScrollContainer);
})