import {onDomReady} from "../../components/dynamic/observer";
import {EffectCreative, Navigation, Swiper} from 'swiper';
// import '../../../node_modules/swiper/swiper.scss';
import '../../../node_modules/swiper/swiper-bundle.min.css';

function init()
{
    const swipeSliderElement = document.querySelector('[data-completed-slider]');
    if (!swipeSliderElement) {
        return;
    }

    const leftArrow = document.querySelector('[data-completed-slider-left]');
    const rightArrow = document.querySelector('[data-completed-slider-right]');

    const slider = new Swiper(swipeSliderElement, {
        modules: [Navigation],
        slidesPerView: "auto",
        speed: 500,
        grabCursor: true,
        preventInteractionOnTransition: true,
        navigation: {
            prevEl: leftArrow,
            nextEl: rightArrow,
        },
    });
}

onDomReady(init);