export default class ScaledImageObject {
    constructor(scaledElement)
    {
        this.scaledContainer = scaledElement;
        this.increasingElement = this.scaledContainer.querySelector('[data-scaled-image]');
        this.ratioFirst = 0.65;
        this.ratioLast = 1;
        this.currentScale = this.ratioFirst; // Текущее значение масштаба
    }

    setStyles(ratio)
    {
        this.increasingElement.style.transform = `scale(${ratio})`;
    }

    update(scroll, container)
    {
        if (!this.increasingElement || !container) return;

        const containerTop = container.getBoundingClientRect().top + window.scrollY;
        const windowHeight = window.innerHeight;

        // Прогресс изменения масштаба
        const progress = (scroll - containerTop + windowHeight) / container.offsetHeight;

        // Линейная интерполяция между ratioFirst и ratioLast
        const ratio = this.ratioFirst + (this.ratioLast - this.ratioFirst) * progress;
        this.currentScale = Math.max(this.ratioFirst, Math.min(this.ratioLast, ratio)); // Ограничиваем значение + обновляем текущий масштаб
        this.setStyles(this.currentScale);
    }

    clearStyles()
    {
        if (this.increasingElement) {
            this.increasingElement.style.transform = `scale(${this.ratioFirst})`;
            this.currentScale = this.ratioFirst; // Сбрасываем текущий масштаб
        }
    }
}
