import {onDomReady} from "../../components/dynamic/observer";
import {Navigation, Swiper} from 'swiper';
import '../../../node_modules/swiper/swiper.scss';

function init() {
    const container = document.querySelector('[data-equipment-slider-container]');

    if (!container) {
        return;
    }

    const equipmentSliderElement = container.querySelector('[data-equipment-slider]');
    if (!equipmentSliderElement) {
        return;
    }

    const leftArrow = container.querySelector('[data-equipment-slider-prev]');
    const rightArrow = container.querySelector('[data-equipment-slider-next]');

    const slider = new Swiper(equipmentSliderElement, {
        modules: [Navigation],
        slidesPerView: "auto",
        speed: 500,
        grabCursor: true,
        preventInteractionOnTransition: true,
        navigation: {
            prevEl: leftArrow,
            nextEl: rightArrow,
        },
        breakpoints: {
            0: {
                spaceBetween: 18
            },
            768: {
                spaceBetween: 32
            },
            1180: {
                spaceBetween: 40
            }
        }
    });
}

onDomReady(init);