import {onDomReady} from "../../components/dynamic/observer";
import {trim} from "lodash";

class CopyUikitToClipboard {
    constructor(uiItem) {
        this.uiItem = uiItem;
        this.addEvents();
    }

    addEvents() {
        this.uiItem.addEventListener('mouseenter', () => {
            this.uiItem.style.cursor = 'copy';
        })
        this.uiItem.addEventListener('click', () => {
            this.copyUiContent();
        });
    }

    async copyUiContent() {
        let text = this.uiItem.innerHTML;

        try {
            await navigator.clipboard.writeText(trim(text));
            console.log('Content copied to clipboard');
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    }
}

onDomReady(() => {
    document.querySelectorAll('.uikit-page__element-description').forEach(uiItem => {
        new CopyUikitToClipboard(uiItem);
    })
})