import {onDomReady} from "../../components/dynamic/observer";

class HoverImage {
    constructor(container)
    {
        this.container = container;
        this.links = null;
        this.images = null;
        this.defaultImage = null;

        this.init();
    }

    init()
    {
        this.initLinks();
        this.initDefaultImage();
        this.initImages();

        this.initEvents();
    }

    initLinks()
    {
        this.links = this.container.querySelectorAll('[data-hover-link]');
    }

    initImages()
    {
        this.images = this.container.querySelectorAll('[data-hover-image]');
    }

    initDefaultImage()
    {
        this.defaultImage = this.container.querySelector('[data-hover-image="default"]');
    }

    initEvents()
    {
        this.links.forEach((link) => {
            this.initLinkEvent(link);
        });
        document.addEventListener('DOMContentMutated', () => {
            this.init()
        })
    }

    initLinkEvent(link)
    {
        link.addEventListener('mouseenter', () => {
            const id = link.dataset.hoverLink;

            const image = this.container.querySelector('[data-hover-image="' + id + '"]');
            this.setActiveImage(image);
        });

        link.addEventListener('mouseleave', () => {
            this.setActiveImage(this.defaultImage);
        })
    }

    setActiveImage(image)
    {
        this.images.forEach((img) => {
            if (img.classList.contains('_active')) {
                img.classList.remove('_active');
            }
        });

        if (image) {
            image.classList.add('_active');
        }
    }
}

onDomReady(() => {
    if (window.innerWidth < 1180) {
        return;
    }

    const containers = document.querySelectorAll('[data-hover-image-container]');

    if (!containers) {
        return;
    }

    containers.forEach((container) => {
        new HoverImage(container);
    });
})