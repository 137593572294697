import { defineStore } from 'pinia';

export const useFilterStore = defineStore('counter', {
  state: () => {
    return {
      filtersData: {},
      filtersValues: {},
      counter: 0,
    }
  },
  getters: {
    getAction(state) {
      return (filterKey) => {
        const filter = state.filtersData[filterKey];
        if (!filter) {
          return null;
        }
        return filter.action ? filter.action : null;
      };
    },
    fieldsByFieldset(state, getters) {
      return (filterKey, fieldsetKey) => {
        const fieldset = this.findFieldset(filterKey, fieldsetKey);
        if (!fieldset) {
          return [];
        }
        return fieldset.fields;
      };
    },
    findFieldset(state) {
      return (filterKey, fieldsetKey) => {
        const filter = state.filtersData[filterKey];
        if (!filter) {
          return null;
        }
        return filter.fieldsets.find((fieldset) => fieldset.key === fieldsetKey);
      };
    },
    findField(state) {
      return (filterKey, fieldKey) => {
        const filter = state.filtersData[filterKey];
        if (!filter) {
          return null;
        }

        let foundField = null;
        filter.fieldsets.forEach((fieldset) => {
          fieldset.fields.forEach((field) => {
            if (field.key === fieldKey) {
              foundField = field;
            }
          })
        });

        return foundField;
      };
    },

    findValueItem(state) {
      return (filterKey, fieldStoreKey) => {
        const filter = state.filtersValues[filterKey];
        if (!filter) {
          return null;
        }
        return filter[fieldStoreKey];
      };
    },

    getValues(state) {
      return (filterKey) => {
        const filterValues = state.filtersValues[filterKey];
        if (!filterValues) {
          return null;
        }
        const values = {};
        Object.entries(filterValues).forEach(([storeKey, valueItem]) => {
          values[storeKey] = valueItem.value;
        })
        return values;
      };
    },
  },
  actions: {
    setFilterData(filterKey, filterData, setUpValues) {
      setUpValues = setUpValues | false;

      const values = {};
      filterData.fieldsets.forEach((fieldset) => {
        fieldset.fields.forEach((field) => {
          const key = field.storeKey;
          const value = field.value;

          if (value || !values[key]) {
            values[key] = {
              value: value
            }
          }

          delete field.value;
        })
      });

      this.filtersData[filterKey] = filterData;
      if (setUpValues) {
        this.filtersValues[filterKey] = values;
      }
    },
    increaseCounter() {
      this.counter++;
    },
    decreaseCounter() {
      this.counter--;
    },
  },
});
