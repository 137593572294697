export default class Slides {
    constructor(list) {
        this.list = list
        this.slides = null;
        this.init();
    }

    init() {
        this.slides = this.list.querySelectorAll('[data-about-scroll-sidebar-item]');
    }

    activeSlide(id) {
        this.slides.forEach((slide) => {
            if (slide.dataset.aboutScrollSidebarItem === id) {
                slide.classList.add('_active');
                const nextAndPrevSlide = this.findNextAndPrevSlide(slide);
                this.addNextAndPrevSlide(nextAndPrevSlide);

            } else {

                slide.classList.remove('_active');
                const nextAndPrevSlide = this.findNextAndPrevSlide(slide);
                this.removeNextAndPrevSlide(nextAndPrevSlide);
            }
        });
    }

    findNextAndPrevSlide(slide) {
        const nextSlide = slide.nextElementSibling;
        const prevSlide = slide.previousElementSibling;

        return {
            nextSlide,
            prevSlide
        }
    }

    addNextAndPrevSlide(nextAndPrevSlide) {
        if (nextAndPrevSlide.nextSlide) {
            nextAndPrevSlide.nextSlide.classList.add('_next');
        }

        if (nextAndPrevSlide.prevSlide) {
            nextAndPrevSlide.prevSlide.classList.add('_prev');
        }
    }

    removeNextAndPrevSlide(nextAndPrevSlide) {
        if (nextAndPrevSlide.nextSlide) {
            nextAndPrevSlide.nextSlide.classList.remove('_next');
        }

        if (nextAndPrevSlide.prevSlide) {
            nextAndPrevSlide.prevSlide.classList.remove('_prev');
        }
    }
}