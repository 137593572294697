export default class ImageObject {
    ratioOffset = 0;
    maxTranslateRatio = 0.2;
    ease = 0.5;
    translate = 0;
    isLarge = window.innerWidth > 1629;
    scale = this.isLarge ? 1.01 : 1.1;

    constructor(container)
    {
        this.container = container; // <picture> или другой родитель элемента img
        this.container.style.overflow = "hidden";
        this.containerHeight = this.container.offsetHeight;
        this.maxTranslate = this.containerHeight * this.maxTranslateRatio;

        this.element = container.querySelector('img');
        if (!this.element) {
            console.log(`ImageObject: could not found img in ${this.container}`);
            return false;
        }

        const rootStyles = getComputedStyle(document.documentElement);
        this.headerHeight = parseInt(rootStyles.getPropertyValue('--header-height'), 10);
    }

    computedRatio()
    {
        const bounds = this.container.getBoundingClientRect();
        const boundsTop = bounds.top;
        const boundsBottom = boundsTop + this.containerHeight;
        const boundsCenter = bounds.top + this.containerHeight / 2;
        const screenCenter = (window.innerHeight + this.headerHeight) / 2;

        if (boundsTop > window.innerHeight) {
            this.ratioOffset = -1;
        } else if (boundsBottom < this.headerHeight) {
            this.ratioOffset = 1;
        } else {
            if (boundsCenter === screenCenter) {
                this.ratioOffset = 0;
            } else {
                this.ratioOffset = ((boundsCenter - screenCenter) / this.containerHeight) * -1;
            }
        }
    }

    computedTranslate()
    {
        this.translate = (1 - this.ease) * this.translate + this.ease * (this.ratioOffset * this.maxTranslate);
    }

    setStyles()
    {
        if (this.element) {
            this.element.style.transform = `translate3d(0, ${this.translate}px, 0) scale(${this.scale})`;
        }
    }

    update()
    {
        this.computedRatio();
        this.computedTranslate();
        this.setStyles();
    }

    clearStyles()
    {
        if (this.element) {
            this.element.style.transform = '';
        }
    }
}