import {onDomReady} from "../../components/dynamic/observer";

class Blackout {
    constructor() {
        this.layout = document.querySelector('.layout');
        this.blackoutElements = null;
        this.header = document.querySelector('[data-header]');

        this.init();
    }

    init() {
        this.initBlackoutElements();
        this.initEvents();
    }

    initBlackoutElements() {
        this.blackoutElements = document.querySelectorAll('[data-hover-blackout]');
    }

    initEvents() {
        this.initBlackoutElementsEvents();
        this.initDropMenuEvents();
    }

    initBlackoutElementsEvents() {
        this.blackoutElements.forEach((element) => {
            element.addEventListener('mouseenter', () => {
                this.addDark();
            });

            element.addEventListener('mouseleave', () => {
                this.removeDark();
            });
        });
    }

    initDropMenuEvents() {
        document.addEventListener('drop-menu-open', () => {
            this.addDark();
        });

        document.addEventListener('drop-menu-close', () => {
            this.removeDark();
        });
    }

    addDark() {
        if (!this.layout.classList.contains('_dark')) {
            this.layout.classList.add('_dark');
        }
    }

    removeDark() {
        if (this.header.classList.contains('_opened')) {
            return;
        }

        if (this.layout.classList.contains('_dark')) {
            this.layout.classList.remove('_dark');
        }
    }
}

onDomReady(() => {
    new Blackout();
})