import {onDomReady} from "../../components/dynamic/observer";
import element from "../../components/accordion/objects/element";
import axios from "axios";

class CityCover {
  constructor(element) {
    this.element = element;
    this.background = element.querySelector('[data-background]');
    this.confirm = element.querySelector('[data-confirm]');
    this.anotherCity = element.querySelector('[data-another-city]')
    this.bind();

    const instance = this;
    setTimeout(() => {
      instance.open();
    }, 1000)
  }

  bind() {
    const instance = this;
    this.background.addEventListener('click', (e) => instance.close());
    this.confirm.addEventListener('click', (e) => {
      e.preventDefault();
      const currentCityId = parseInt(e.target.dataset.confirm, 10);
      axios.post('/city/confirm-city', {
        cityId: currentCityId
      });
      instance.close();
    });

    this.anotherCity.addEventListener('click', (e) => {
      e.preventDefault();
      instance.element.classList.add('_to-choice');
      document.dispatchEvent(new Event('CityCoverOpen'));
    });
  }

  open() {
    this.element.classList.add('_show');
    document.dispatchEvent(new Event('CityCoverOpen'));
  }

  close() {
    this.element.classList.remove('_show');
  }
}
function init() {
  const element = document.querySelector('[data-city-cover]');
  if (element) {
    new CityCover(element);
  }
}

onDomReady(init);