import {onDomReady} from '../../components/dynamic/observer';
import ImageObject from './objects/ImageObject';
import HeroObject from "./objects/HeroObject";

class Parallax {
    offsetY = 0;
    maxMediumWidth = 1179;

    constructor() {
        if (window.innerWidth > this.maxMediumWidth) {
            this.initObjects();
            this.eventListeners();
            this.update();
        }
    }

    initObjects() {
        this.initHeroObject();
        this.initImageObjects();
    }

    initImageObjects() {
        this.imageObjects = [];
        Array.from(document.querySelectorAll('[data-parallax-image-container]'))
            .forEach((container) => {
                this.imageObjects.push(new ImageObject(container));
            });
    }

    initHeroObject() {
        if (document.querySelector('[data-hero]')) {
            this.heroObject = new HeroObject(document.querySelector('[data-hero]'));
        }
    }

    destroyObjects() {
        this.heroObject.clearStyles();
        this.heroObject = null;

        this.phoneObject = null;

        this.imageObjects.forEach((slide) => {
            slide.clearStyles();
        });
        this.imageObjects = [];

        this.productSliderObject.clearStyles();
        this.productSliderObject = null;

        this.productPictureSliderObject.clearStyles();
        this.productPictureSliderObject = null;

        this.caseSliderObject.clearStyles();
        this.caseSliderObject = null;
    }

    eventListeners() {
        document.addEventListener('scroll', () => {
            this.offsetY = window.scrollY;
        });

        window.addEventListener('resize', () => {
            if (window.innerWidth > this.maxMediumWidth) {
                this.initObjects();
            } else {
                this.destroyObjects();
            }
        });
    }

    update() {
        if (this.heroObject) {
            this.heroObject.update(this.offsetY);
        }

        if (this.phoneObject) {
            this.phoneObject.update(this.offsetY);
        }

        if (this.productSliderObject) {
            this.productSliderObject.update();
        }

        if (this.caseSliderObject) {
            this.caseSliderObject.update();
        }

        if (this.productPictureSliderObject) {
            this.productPictureSliderObject.update();
        }

        if (this.imageObjects) {
            this.updateImageObjects();
        }

        window.requestAnimationFrame(this.update.bind(this));
    }

    updateImageObjects() {
        this.imageObjects.forEach((imageObject) => {
            imageObject.update();
        });
    }
}

onDomReady(() => {
    const parallax = new Parallax();
});