import {onDomReady} from "../../components/dynamic/observer";
import {EffectCreative, Navigation, Pagination, Swiper} from 'swiper';
import '../../../node_modules/swiper/swiper.scss';

function init() {
    const sliderContainer = document.querySelector('[data-index-reviews-slider-container]');

    if (!sliderContainer) {
        return;
    }

    const swipeSliderElement = sliderContainer.querySelector('[data-index-reviews-slider]');
    if (!swipeSliderElement) {
        return;
    }

    const leftArrow = document.querySelector('[data-index-reviews-slider-prev]');
    const rightArrow = document.querySelector('[data-index-reviews-slider-next]');
    const pagination = sliderContainer.querySelector('[data-index-reviews-slider-pagination]');

    const slider = new Swiper(swipeSliderElement, {
        modules: [Navigation, Pagination, EffectCreative],
        slidesPerView: "auto",
        speed: 1000,
        loop: true,
        preventInteractionOnTransition: true,
        effect: 'creative',
        creativeEffect: {
            prev: {
                translate: ['0%', 0, 0],
            },
            next: {
                translate: ['0%', 0, 0],
            },
        },
        navigation: {
            prevEl: leftArrow,
            nextEl: rightArrow,
        },
        pagination:{
            el: pagination,
            type: 'fraction',
            renderFraction: function (currentClass, totalClass) {
                return '<span class="' + currentClass + ' text__caption-middle"></span>' +
                    '<span class="swiper-pagination-line text__caption-middle">/</span>' +
                    '<span class="' + totalClass + ' text__caption-middle"></span>';
            }
        },
    });
}

onDomReady(init);