import {onDomReady} from "../../components/dynamic/observer";

onDomReady(() => {
    document.querySelectorAll('[data-hover-me]').forEach((hoverMeEl) => {
        const key = hoverMeEl.dataset.hoverMe;

        let drop = false; // если хотим, чтобы класс не пропадал
        if (hoverMeEl.dataset.hoverMeDrop) {
            drop = hoverMeEl.dataset.hoverMeDrop; // если хотим, чтобы класс пропадал -- true
        }

        const hoverMeTooEl = document.querySelector(`[data-hover-me-too="${key}"]`);
        if (!hoverMeTooEl) {
            return;
        }

        const hoverMeTooGroup = hoverMeTooEl.dataset.hoverMeTooGroup;

        hoverMeEl.addEventListener('mouseover', () => {

            document.querySelectorAll(
                `[data-hover-me-too-group="${hoverMeTooGroup}"]`
            ).forEach((hoverMeTooSameGroupEl) => {
                if (hoverMeTooSameGroupEl === hoverMeTooEl) {
                    return;
                }
                hoverMeTooSameGroupEl.classList.remove('_hover');
            });

        hoverMeTooEl.classList.add('_hover');
        });

    if (drop) {
        hoverMeEl.addEventListener('mouseleave', () => {
            if (hoverMeTooEl.classList.contains('_hover')) {
              // hoverMeTooEl.classList.add('_hover-out');

                hoverMeTooEl.classList.remove('_hover');
                setTimeout(() => {
                    // hoverMeTooEl.classList.remove('_hover-out');
                }, 500);
            }
        });
    }
    })
});