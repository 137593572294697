require('../scss/app.scss');
require('./svg/svg.js');

require("../fonts/Actay_wide/stylesheet.css");
require("../fonts/Wix_Madefor_Display/static/stylesheet.css");

window.noZensmooth = true;

require('../vue/app');
require('@vueform/slider/themes/default.css')

require('./sliders/sliders.js');

require('./common/cursor');
require('./common/true-vh');
require('./common/goals2');
require('./common/js-validation');
require('./common/links');
require('./common/switcher');
require('./common/main');
require('./common/pagination');
require('./common/respond');
require('./common/need-animation');
require('./common/webp-polyfill');
require('./common/text-wrap-creator');
require('./common/photoswipe');
require('./common/hover-me-too');
require('./common/underline-text');
require('./common/copyUikitToClipboard');
require('./common/change-price');
require('./common/change-height');
require('./common-map/map');

require('./header/index');

require('fslightbox');
require('./lightbox/lightbox');

require('./captcha/yandex-captcha');

require('./accordion/initializator');
require('./accordion/switchable-accordion');

require('./field/phone.js');
require('./field/floating.js');
require('./field/multi-uploadable.js');

// require('./field/range.js'); // Раскоментить при необходимости
require('../components/smart-tabs/smart-tabs');
require('../components/smart-tabs/smart-tabs-multiply');
require('./city/select-city-form');
require('./city/city-cover');
require('./city/city-opener');

require('./parallax/index');
require('./open-text/open-text');
require('./scale/index');

require('./hover-image/hoverImage');
require('./layout-blackout/blackout');
require('./about-scroll/index');

require('./article/article-title');