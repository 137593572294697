export default class HeroObject {
    maxTranslateRatio = 0.2;
    ease = 0.5;
    translate = 0;

    constructor(heroElement)
    {
        this.heroContainer = heroElement;
        this.trabslatableElement = this.heroContainer.querySelector('[data-hero-translatable]');
    }

    computedTranslate(offsetY)
    {
        if (offsetY > window.innerHeight) {
            this.translate = window.innerHeight;
        } else if (offsetY === 0) {
            this.translate = 0;
        } else {
            this.translate = (1 - this.ease) * this.translate + this.ease * offsetY;
        }
    }

    setStyles()
    {
        if (this.trabslatableElement) {
            this.trabslatableElement.style.transform = `translate3d(0, ${this.translate * this.maxTranslateRatio}px, 0)`;
        }
    }

    update(offsetY)
    {
        this.computedTranslate(offsetY);
        this.setStyles();
    }

    clearStyles()
    {
        this.trabslatableElement.style.transform = '';
    }
}