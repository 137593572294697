function initMapGoogle()
{
    /*
    let infowindow = new google.maps.InfoWindow({
      content: '<div class="contacts-lines popup">' + $('.contacts-lines').html() + '</div>'
    });
    */

    let customMapType = new google.maps.StyledMapType([
        {
            "tags": "country",
            "elements": "geometry.fill",
            "stylers": [
                {
                    "color": "#ad9985"
                },
                {
                    "opacity": 0.8,
                    "zoom": 0
                },
                {
                    "opacity": 0.8,
                    "zoom": 1
                },
                {
                    "opacity": 0.8,
                    "zoom": 2
                },
                {
                    "opacity": 0.8,
                    "zoom": 3
                },
                {
                    "opacity": 0.8,
                    "zoom": 4
                },
                {
                    "opacity": 1,
                    "zoom": 5
                },
                {
                    "opacity": 1,
                    "zoom": 6
                },
                {
                    "opacity": 1,
                    "zoom": 7
                },
                {
                    "opacity": 1,
                    "zoom": 8
                },
                {
                    "opacity": 1,
                    "zoom": 9
                },
                {
                    "opacity": 1,
                    "zoom": 10
                },
                {
                    "opacity": 1,
                    "zoom": 11
                },
                {
                    "opacity": 1,
                    "zoom": 12
                },
                {
                    "opacity": 1,
                    "zoom": 13
                },
                {
                    "opacity": 1,
                    "zoom": 14
                },
                {
                    "opacity": 1,
                    "zoom": 15
                },
                {
                    "opacity": 1,
                    "zoom": 16
                },
                {
                    "opacity": 1,
                    "zoom": 17
                },
                {
                    "opacity": 1,
                    "zoom": 18
                },
                {
                    "opacity": 1,
                    "zoom": 19
                },
                {
                    "opacity": 1,
                    "zoom": 20
                },
                {
                    "opacity": 1,
                    "zoom": 21
                }
            ]
        },
        {
            "tags": "country",
            "elements": "geometry.outline",
            "stylers": [
                {
                    "color": "#d3ccc5"
                },
                {
                    "opacity": 0.15,
                    "zoom": 0
                },
                {
                    "opacity": 0.15,
                    "zoom": 1
                },
                {
                    "opacity": 0.15,
                    "zoom": 2
                },
                {
                    "opacity": 0.15,
                    "zoom": 3
                },
                {
                    "opacity": 0.15,
                    "zoom": 4
                },
                {
                    "opacity": 0.15,
                    "zoom": 5
                },
                {
                    "opacity": 0.25,
                    "zoom": 6
                },
                {
                    "opacity": 0.5,
                    "zoom": 7
                },
                {
                    "opacity": 0.47,
                    "zoom": 8
                },
                {
                    "opacity": 0.44,
                    "zoom": 9
                },
                {
                    "opacity": 0.41,
                    "zoom": 10
                },
                {
                    "opacity": 0.38,
                    "zoom": 11
                },
                {
                    "opacity": 0.35,
                    "zoom": 12
                },
                {
                    "opacity": 0.33,
                    "zoom": 13
                },
                {
                    "opacity": 0.3,
                    "zoom": 14
                },
                {
                    "opacity": 0.28,
                    "zoom": 15
                },
                {
                    "opacity": 0.25,
                    "zoom": 16
                },
                {
                    "opacity": 0.25,
                    "zoom": 17
                },
                {
                    "opacity": 0.25,
                    "zoom": 18
                },
                {
                    "opacity": 0.25,
                    "zoom": 19
                },
                {
                    "opacity": 0.25,
                    "zoom": 20
                },
                {
                    "opacity": 0.25,
                    "zoom": 21
                }
            ]
        },
        {
            "tags": "region",
            "elements": "geometry.fill",
            "stylers": [
                {
                    "color": "#c2b3a3",
                    "opacity": 0.5,
                    "zoom": 0
                },
                {
                    "color": "#c2b3a3",
                    "opacity": 0.5,
                    "zoom": 1
                },
                {
                    "color": "#c2b3a3",
                    "opacity": 0.5,
                    "zoom": 2
                },
                {
                    "color": "#c2b3a3",
                    "opacity": 0.5,
                    "zoom": 3
                },
                {
                    "color": "#c2b3a3",
                    "opacity": 0.5,
                    "zoom": 4
                },
                {
                    "color": "#c2b3a3",
                    "opacity": 0.5,
                    "zoom": 5
                },
                {
                    "color": "#c2b3a3",
                    "opacity": 1,
                    "zoom": 6
                },
                {
                    "color": "#c2b3a3",
                    "opacity": 1,
                    "zoom": 7
                },
                {
                    "color": "#ad9985",
                    "opacity": 1,
                    "zoom": 8
                },
                {
                    "color": "#ad9985",
                    "opacity": 1,
                    "zoom": 9
                },
                {
                    "color": "#ad9985",
                    "opacity": 1,
                    "zoom": 10
                },
                {
                    "color": "#ad9985",
                    "opacity": 1,
                    "zoom": 11
                },
                {
                    "color": "#ad9985",
                    "opacity": 1,
                    "zoom": 12
                },
                {
                    "color": "#ad9985",
                    "opacity": 1,
                    "zoom": 13
                },
                {
                    "color": "#ad9985",
                    "opacity": 1,
                    "zoom": 14
                },
                {
                    "color": "#ad9985",
                    "opacity": 1,
                    "zoom": 15
                },
                {
                    "color": "#ad9985",
                    "opacity": 1,
                    "zoom": 16
                },
                {
                    "color": "#ad9985",
                    "opacity": 1,
                    "zoom": 17
                },
                {
                    "color": "#ad9985",
                    "opacity": 1,
                    "zoom": 18
                },
                {
                    "color": "#ad9985",
                    "opacity": 1,
                    "zoom": 19
                },
                {
                    "color": "#ad9985",
                    "opacity": 1,
                    "zoom": 20
                },
                {
                    "color": "#ad9985",
                    "opacity": 1,
                    "zoom": 21
                }
            ]
        },
        {
            "tags": "region",
            "elements": "geometry.outline",
            "stylers": [
                {
                    "color": "#d3ccc5"
                },
                {
                    "opacity": 0.15,
                    "zoom": 0
                },
                {
                    "opacity": 0.15,
                    "zoom": 1
                },
                {
                    "opacity": 0.15,
                    "zoom": 2
                },
                {
                    "opacity": 0.15,
                    "zoom": 3
                },
                {
                    "opacity": 0.15,
                    "zoom": 4
                },
                {
                    "opacity": 0.15,
                    "zoom": 5
                },
                {
                    "opacity": 0.25,
                    "zoom": 6
                },
                {
                    "opacity": 0.5,
                    "zoom": 7
                },
                {
                    "opacity": 0.47,
                    "zoom": 8
                },
                {
                    "opacity": 0.44,
                    "zoom": 9
                },
                {
                    "opacity": 0.41,
                    "zoom": 10
                },
                {
                    "opacity": 0.38,
                    "zoom": 11
                },
                {
                    "opacity": 0.35,
                    "zoom": 12
                },
                {
                    "opacity": 0.33,
                    "zoom": 13
                },
                {
                    "opacity": 0.3,
                    "zoom": 14
                },
                {
                    "opacity": 0.28,
                    "zoom": 15
                },
                {
                    "opacity": 0.25,
                    "zoom": 16
                },
                {
                    "opacity": 0.25,
                    "zoom": 17
                },
                {
                    "opacity": 0.25,
                    "zoom": 18
                },
                {
                    "opacity": 0.25,
                    "zoom": 19
                },
                {
                    "opacity": 0.25,
                    "zoom": 20
                },
                {
                    "opacity": 0.25,
                    "zoom": 21
                }
            ]
        },
        {
            "tags": {
                "any": "admin",
                "none": [
                    "country",
                    "region",
                    "locality",
                    "district",
                    "address"
                ]
            },
            "elements": "geometry.fill",
            "stylers": [
                {
                    "color": "#ad9985"
                },
                {
                    "opacity": 0.5,
                    "zoom": 0
                },
                {
                    "opacity": 0.5,
                    "zoom": 1
                },
                {
                    "opacity": 0.5,
                    "zoom": 2
                },
                {
                    "opacity": 0.5,
                    "zoom": 3
                },
                {
                    "opacity": 0.5,
                    "zoom": 4
                },
                {
                    "opacity": 0.5,
                    "zoom": 5
                },
                {
                    "opacity": 1,
                    "zoom": 6
                },
                {
                    "opacity": 1,
                    "zoom": 7
                },
                {
                    "opacity": 1,
                    "zoom": 8
                },
                {
                    "opacity": 1,
                    "zoom": 9
                },
                {
                    "opacity": 1,
                    "zoom": 10
                },
                {
                    "opacity": 1,
                    "zoom": 11
                },
                {
                    "opacity": 1,
                    "zoom": 12
                },
                {
                    "opacity": 1,
                    "zoom": 13
                },
                {
                    "opacity": 1,
                    "zoom": 14
                },
                {
                    "opacity": 1,
                    "zoom": 15
                },
                {
                    "opacity": 1,
                    "zoom": 16
                },
                {
                    "opacity": 1,
                    "zoom": 17
                },
                {
                    "opacity": 1,
                    "zoom": 18
                },
                {
                    "opacity": 1,
                    "zoom": 19
                },
                {
                    "opacity": 1,
                    "zoom": 20
                },
                {
                    "opacity": 1,
                    "zoom": 21
                }
            ]
        },
        {
            "tags": {
                "any": "admin",
                "none": [
                    "country",
                    "region",
                    "locality",
                    "district",
                    "address"
                ]
            },
            "elements": "geometry.outline",
            "stylers": [
                {
                    "color": "#d3ccc5"
                },
                {
                    "opacity": 0.15,
                    "zoom": 0
                },
                {
                    "opacity": 0.15,
                    "zoom": 1
                },
                {
                    "opacity": 0.15,
                    "zoom": 2
                },
                {
                    "opacity": 0.15,
                    "zoom": 3
                },
                {
                    "opacity": 0.15,
                    "zoom": 4
                },
                {
                    "opacity": 0.15,
                    "zoom": 5
                },
                {
                    "opacity": 0.25,
                    "zoom": 6
                },
                {
                    "opacity": 0.5,
                    "zoom": 7
                },
                {
                    "opacity": 0.47,
                    "zoom": 8
                },
                {
                    "opacity": 0.44,
                    "zoom": 9
                },
                {
                    "opacity": 0.41,
                    "zoom": 10
                },
                {
                    "opacity": 0.38,
                    "zoom": 11
                },
                {
                    "opacity": 0.35,
                    "zoom": 12
                },
                {
                    "opacity": 0.33,
                    "zoom": 13
                },
                {
                    "opacity": 0.3,
                    "zoom": 14
                },
                {
                    "opacity": 0.28,
                    "zoom": 15
                },
                {
                    "opacity": 0.25,
                    "zoom": 16
                },
                {
                    "opacity": 0.25,
                    "zoom": 17
                },
                {
                    "opacity": 0.25,
                    "zoom": 18
                },
                {
                    "opacity": 0.25,
                    "zoom": 19
                },
                {
                    "opacity": 0.25,
                    "zoom": 20
                },
                {
                    "opacity": 0.25,
                    "zoom": 21
                }
            ]
        },
        {
            "tags": {
                "any": "landcover",
                "none": "vegetation"
            },
            "stylers": [
                {
                    "hue": "#d5cfc8"
                }
            ]
        },
        {
            "tags": "vegetation",
            "elements": "geometry",
            "stylers": [
                {
                    "color": "#bfb6ab",
                    "opacity": 0.1,
                    "zoom": 0
                },
                {
                    "color": "#bfb6ab",
                    "opacity": 0.1,
                    "zoom": 1
                },
                {
                    "color": "#bfb6ab",
                    "opacity": 0.1,
                    "zoom": 2
                },
                {
                    "color": "#bfb6ab",
                    "opacity": 0.1,
                    "zoom": 3
                },
                {
                    "color": "#bfb6ab",
                    "opacity": 0.1,
                    "zoom": 4
                },
                {
                    "color": "#bfb6ab",
                    "opacity": 0.1,
                    "zoom": 5
                },
                {
                    "color": "#bfb6ab",
                    "opacity": 0.2,
                    "zoom": 6
                },
                {
                    "color": "#d5cfc8",
                    "opacity": 0.3,
                    "zoom": 7
                },
                {
                    "color": "#d5cfc8",
                    "opacity": 0.4,
                    "zoom": 8
                },
                {
                    "color": "#d5cfc8",
                    "opacity": 0.6,
                    "zoom": 9
                },
                {
                    "color": "#d5cfc8",
                    "opacity": 0.8,
                    "zoom": 10
                },
                {
                    "color": "#d5cfc8",
                    "opacity": 1,
                    "zoom": 11
                },
                {
                    "color": "#d5cfc8",
                    "opacity": 1,
                    "zoom": 12
                },
                {
                    "color": "#d5cfc8",
                    "opacity": 1,
                    "zoom": 13
                },
                {
                    "color": "#d9d4ce",
                    "opacity": 1,
                    "zoom": 14
                },
                {
                    "color": "#ded9d4",
                    "opacity": 1,
                    "zoom": 15
                },
                {
                    "color": "#ded9d4",
                    "opacity": 1,
                    "zoom": 16
                },
                {
                    "color": "#ded9d4",
                    "opacity": 1,
                    "zoom": 17
                },
                {
                    "color": "#ded9d4",
                    "opacity": 1,
                    "zoom": 18
                },
                {
                    "color": "#ded9d4",
                    "opacity": 1,
                    "zoom": 19
                },
                {
                    "color": "#ded9d4",
                    "opacity": 1,
                    "zoom": 20
                },
                {
                    "color": "#ded9d4",
                    "opacity": 1,
                    "zoom": 21
                }
            ]
        },
        {
            "tags": "park",
            "elements": "geometry",
            "stylers": [
                {
                    "color": "#d5cfc8",
                    "opacity": 0.1,
                    "zoom": 0
                },
                {
                    "color": "#d5cfc8",
                    "opacity": 0.1,
                    "zoom": 1
                },
                {
                    "color": "#d5cfc8",
                    "opacity": 0.1,
                    "zoom": 2
                },
                {
                    "color": "#d5cfc8",
                    "opacity": 0.1,
                    "zoom": 3
                },
                {
                    "color": "#d5cfc8",
                    "opacity": 0.1,
                    "zoom": 4
                },
                {
                    "color": "#d5cfc8",
                    "opacity": 0.1,
                    "zoom": 5
                },
                {
                    "color": "#d5cfc8",
                    "opacity": 0.2,
                    "zoom": 6
                },
                {
                    "color": "#d5cfc8",
                    "opacity": 0.3,
                    "zoom": 7
                },
                {
                    "color": "#d5cfc8",
                    "opacity": 0.4,
                    "zoom": 8
                },
                {
                    "color": "#d5cfc8",
                    "opacity": 0.6,
                    "zoom": 9
                },
                {
                    "color": "#d5cfc8",
                    "opacity": 0.8,
                    "zoom": 10
                },
                {
                    "color": "#d5cfc8",
                    "opacity": 1,
                    "zoom": 11
                },
                {
                    "color": "#d5cfc8",
                    "opacity": 1,
                    "zoom": 12
                },
                {
                    "color": "#d5cfc8",
                    "opacity": 1,
                    "zoom": 13
                },
                {
                    "color": "#d9d4ce",
                    "opacity": 1,
                    "zoom": 14
                },
                {
                    "color": "#ded9d4",
                    "opacity": 1,
                    "zoom": 15
                },
                {
                    "color": "#ded9d4",
                    "opacity": 0.9,
                    "zoom": 16
                },
                {
                    "color": "#ded9d4",
                    "opacity": 0.8,
                    "zoom": 17
                },
                {
                    "color": "#ded9d4",
                    "opacity": 0.7,
                    "zoom": 18
                },
                {
                    "color": "#ded9d4",
                    "opacity": 0.7,
                    "zoom": 19
                },
                {
                    "color": "#ded9d4",
                    "opacity": 0.7,
                    "zoom": 20
                },
                {
                    "color": "#ded9d4",
                    "opacity": 0.7,
                    "zoom": 21
                }
            ]
        },
        {
            "tags": "national_park",
            "elements": "geometry",
            "stylers": [
                {
                    "color": "#d5cfc8",
                    "opacity": 0.1,
                    "zoom": 0
                },
                {
                    "color": "#d5cfc8",
                    "opacity": 0.1,
                    "zoom": 1
                },
                {
                    "color": "#d5cfc8",
                    "opacity": 0.1,
                    "zoom": 2
                },
                {
                    "color": "#d5cfc8",
                    "opacity": 0.1,
                    "zoom": 3
                },
                {
                    "color": "#d5cfc8",
                    "opacity": 0.1,
                    "zoom": 4
                },
                {
                    "color": "#d5cfc8",
                    "opacity": 0.1,
                    "zoom": 5
                },
                {
                    "color": "#d5cfc8",
                    "opacity": 0.2,
                    "zoom": 6
                },
                {
                    "color": "#d5cfc8",
                    "opacity": 0.3,
                    "zoom": 7
                },
                {
                    "color": "#d5cfc8",
                    "opacity": 0.4,
                    "zoom": 8
                },
                {
                    "color": "#d5cfc8",
                    "opacity": 0.6,
                    "zoom": 9
                },
                {
                    "color": "#d5cfc8",
                    "opacity": 0.8,
                    "zoom": 10
                },
                {
                    "color": "#d5cfc8",
                    "opacity": 1,
                    "zoom": 11
                },
                {
                    "color": "#d5cfc8",
                    "opacity": 1,
                    "zoom": 12
                },
                {
                    "color": "#d5cfc8",
                    "opacity": 1,
                    "zoom": 13
                },
                {
                    "color": "#d9d4ce",
                    "opacity": 1,
                    "zoom": 14
                },
                {
                    "color": "#ded9d4",
                    "opacity": 1,
                    "zoom": 15
                },
                {
                    "color": "#ded9d4",
                    "opacity": 0.7,
                    "zoom": 16
                },
                {
                    "color": "#ded9d4",
                    "opacity": 0.7,
                    "zoom": 17
                },
                {
                    "color": "#ded9d4",
                    "opacity": 0.7,
                    "zoom": 18
                },
                {
                    "color": "#ded9d4",
                    "opacity": 0.7,
                    "zoom": 19
                },
                {
                    "color": "#ded9d4",
                    "opacity": 0.7,
                    "zoom": 20
                },
                {
                    "color": "#ded9d4",
                    "opacity": 0.7,
                    "zoom": 21
                }
            ]
        },
        {
            "tags": "cemetery",
            "elements": "geometry",
            "stylers": [
                {
                    "color": "#d5cfc8",
                    "zoom": 0
                },
                {
                    "color": "#d5cfc8",
                    "zoom": 1
                },
                {
                    "color": "#d5cfc8",
                    "zoom": 2
                },
                {
                    "color": "#d5cfc8",
                    "zoom": 3
                },
                {
                    "color": "#d5cfc8",
                    "zoom": 4
                },
                {
                    "color": "#d5cfc8",
                    "zoom": 5
                },
                {
                    "color": "#d5cfc8",
                    "zoom": 6
                },
                {
                    "color": "#d5cfc8",
                    "zoom": 7
                },
                {
                    "color": "#d5cfc8",
                    "zoom": 8
                },
                {
                    "color": "#d5cfc8",
                    "zoom": 9
                },
                {
                    "color": "#d5cfc8",
                    "zoom": 10
                },
                {
                    "color": "#d5cfc8",
                    "zoom": 11
                },
                {
                    "color": "#d5cfc8",
                    "zoom": 12
                },
                {
                    "color": "#d5cfc8",
                    "zoom": 13
                },
                {
                    "color": "#d9d4ce",
                    "zoom": 14
                },
                {
                    "color": "#ded9d4",
                    "zoom": 15
                },
                {
                    "color": "#ded9d4",
                    "zoom": 16
                },
                {
                    "color": "#ded9d4",
                    "zoom": 17
                },
                {
                    "color": "#ded9d4",
                    "zoom": 18
                },
                {
                    "color": "#ded9d4",
                    "zoom": 19
                },
                {
                    "color": "#ded9d4",
                    "zoom": 20
                },
                {
                    "color": "#ded9d4",
                    "zoom": 21
                }
            ]
        },
        {
            "tags": "sports_ground",
            "elements": "geometry",
            "stylers": [
                {
                    "color": "#cac2ba",
                    "opacity": 0,
                    "zoom": 0
                },
                {
                    "color": "#cac2ba",
                    "opacity": 0,
                    "zoom": 1
                },
                {
                    "color": "#cac2ba",
                    "opacity": 0,
                    "zoom": 2
                },
                {
                    "color": "#cac2ba",
                    "opacity": 0,
                    "zoom": 3
                },
                {
                    "color": "#cac2ba",
                    "opacity": 0,
                    "zoom": 4
                },
                {
                    "color": "#cac2ba",
                    "opacity": 0,
                    "zoom": 5
                },
                {
                    "color": "#cac2ba",
                    "opacity": 0,
                    "zoom": 6
                },
                {
                    "color": "#cac2ba",
                    "opacity": 0,
                    "zoom": 7
                },
                {
                    "color": "#cac2ba",
                    "opacity": 0,
                    "zoom": 8
                },
                {
                    "color": "#cac2ba",
                    "opacity": 0,
                    "zoom": 9
                },
                {
                    "color": "#cac2ba",
                    "opacity": 0,
                    "zoom": 10
                },
                {
                    "color": "#cac2ba",
                    "opacity": 0,
                    "zoom": 11
                },
                {
                    "color": "#cac2ba",
                    "opacity": 0,
                    "zoom": 12
                },
                {
                    "color": "#cac2ba",
                    "opacity": 0,
                    "zoom": 13
                },
                {
                    "color": "#cec7bf",
                    "opacity": 0,
                    "zoom": 14
                },
                {
                    "color": "#d3ccc5",
                    "opacity": 0.5,
                    "zoom": 15
                },
                {
                    "color": "#d4cdc6",
                    "opacity": 1,
                    "zoom": 16
                },
                {
                    "color": "#d4cec7",
                    "opacity": 1,
                    "zoom": 17
                },
                {
                    "color": "#d5cec8",
                    "opacity": 1,
                    "zoom": 18
                },
                {
                    "color": "#d6cfc9",
                    "opacity": 1,
                    "zoom": 19
                },
                {
                    "color": "#d6d0ca",
                    "opacity": 1,
                    "zoom": 20
                },
                {
                    "color": "#d7d1cb",
                    "opacity": 1,
                    "zoom": 21
                }
            ]
        },
        {
            "tags": "terrain",
            "elements": "geometry",
            "stylers": [
                {
                    "hue": "#e9e6e2"
                },
                {
                    "opacity": 0.3,
                    "zoom": 0
                },
                {
                    "opacity": 0.3,
                    "zoom": 1
                },
                {
                    "opacity": 0.3,
                    "zoom": 2
                },
                {
                    "opacity": 0.3,
                    "zoom": 3
                },
                {
                    "opacity": 0.3,
                    "zoom": 4
                },
                {
                    "opacity": 0.35,
                    "zoom": 5
                },
                {
                    "opacity": 0.4,
                    "zoom": 6
                },
                {
                    "opacity": 0.6,
                    "zoom": 7
                },
                {
                    "opacity": 0.8,
                    "zoom": 8
                },
                {
                    "opacity": 0.9,
                    "zoom": 9
                },
                {
                    "opacity": 1,
                    "zoom": 10
                },
                {
                    "opacity": 1,
                    "zoom": 11
                },
                {
                    "opacity": 1,
                    "zoom": 12
                },
                {
                    "opacity": 1,
                    "zoom": 13
                },
                {
                    "opacity": 1,
                    "zoom": 14
                },
                {
                    "opacity": 1,
                    "zoom": 15
                },
                {
                    "opacity": 1,
                    "zoom": 16
                },
                {
                    "opacity": 1,
                    "zoom": 17
                },
                {
                    "opacity": 1,
                    "zoom": 18
                },
                {
                    "opacity": 1,
                    "zoom": 19
                },
                {
                    "opacity": 1,
                    "zoom": 20
                },
                {
                    "opacity": 1,
                    "zoom": 21
                }
            ]
        },
        {
            "tags": "geographic_line",
            "elements": "geometry",
            "stylers": [
                {
                    "color": "#91826e"
                }
            ]
        },
        {
            "tags": "land",
            "elements": "geometry",
            "stylers": [
                {
                    "color": "#e9e5e2",
                    "zoom": 0
                },
                {
                    "color": "#e9e5e2",
                    "zoom": 1
                },
                {
                    "color": "#e9e5e2",
                    "zoom": 2
                },
                {
                    "color": "#e9e5e2",
                    "zoom": 3
                },
                {
                    "color": "#e9e5e2",
                    "zoom": 4
                },
                {
                    "color": "#ebe8e5",
                    "zoom": 5
                },
                {
                    "color": "#edeae8",
                    "zoom": 6
                },
                {
                    "color": "#f0edeb",
                    "zoom": 7
                },
                {
                    "color": "#f2f0ee",
                    "zoom": 8
                },
                {
                    "color": "#f2f0ee",
                    "zoom": 9
                },
                {
                    "color": "#f2f0ee",
                    "zoom": 10
                },
                {
                    "color": "#f2f0ee",
                    "zoom": 11
                },
                {
                    "color": "#f2f0ee",
                    "zoom": 12
                },
                {
                    "color": "#f2f0ee",
                    "zoom": 13
                },
                {
                    "color": "#f4f2f0",
                    "zoom": 14
                },
                {
                    "color": "#f6f5f3",
                    "zoom": 15
                },
                {
                    "color": "#f6f5f3",
                    "zoom": 16
                },
                {
                    "color": "#f7f6f4",
                    "zoom": 17
                },
                {
                    "color": "#f7f6f4",
                    "zoom": 18
                },
                {
                    "color": "#f7f6f5",
                    "zoom": 19
                },
                {
                    "color": "#f8f7f5",
                    "zoom": 20
                },
                {
                    "color": "#f8f7f6",
                    "zoom": 21
                }
            ]
        },
        {
            "tags": "residential",
            "elements": "geometry",
            "stylers": [
                {
                    "color": "#e9e6e2",
                    "opacity": 0.5,
                    "zoom": 0
                },
                {
                    "color": "#e9e6e2",
                    "opacity": 0.5,
                    "zoom": 1
                },
                {
                    "color": "#e9e6e2",
                    "opacity": 0.5,
                    "zoom": 2
                },
                {
                    "color": "#e9e6e2",
                    "opacity": 0.5,
                    "zoom": 3
                },
                {
                    "color": "#e9e6e2",
                    "opacity": 0.5,
                    "zoom": 4
                },
                {
                    "color": "#e9e6e2",
                    "opacity": 0.5,
                    "zoom": 5
                },
                {
                    "color": "#e9e6e2",
                    "opacity": 0.5,
                    "zoom": 6
                },
                {
                    "color": "#e9e6e2",
                    "opacity": 0.5,
                    "zoom": 7
                },
                {
                    "color": "#e9e6e2",
                    "opacity": 0.5,
                    "zoom": 8
                },
                {
                    "color": "#e9e6e2",
                    "opacity": 0.5,
                    "zoom": 9
                },
                {
                    "color": "#e9e6e2",
                    "opacity": 0.5,
                    "zoom": 10
                },
                {
                    "color": "#e9e6e2",
                    "opacity": 0.5,
                    "zoom": 11
                },
                {
                    "color": "#e9e6e2",
                    "opacity": 0.5,
                    "zoom": 12
                },
                {
                    "color": "#e9e6e2",
                    "opacity": 1,
                    "zoom": 13
                },
                {
                    "color": "#edebe8",
                    "opacity": 1,
                    "zoom": 14
                },
                {
                    "color": "#f2f0ee",
                    "opacity": 1,
                    "zoom": 15
                },
                {
                    "color": "#f3f1ef",
                    "opacity": 1,
                    "zoom": 16
                },
                {
                    "color": "#f3f2f0",
                    "opacity": 1,
                    "zoom": 17
                },
                {
                    "color": "#f4f2f0",
                    "opacity": 1,
                    "zoom": 18
                },
                {
                    "color": "#f5f3f1",
                    "opacity": 1,
                    "zoom": 19
                },
                {
                    "color": "#f5f4f2",
                    "opacity": 1,
                    "zoom": 20
                },
                {
                    "color": "#f6f5f3",
                    "opacity": 1,
                    "zoom": 21
                }
            ]
        },
        {
            "tags": "locality",
            "elements": "geometry",
            "stylers": [
                {
                    "color": "#e9e6e2",
                    "zoom": 0
                },
                {
                    "color": "#e9e6e2",
                    "zoom": 1
                },
                {
                    "color": "#e9e6e2",
                    "zoom": 2
                },
                {
                    "color": "#e9e6e2",
                    "zoom": 3
                },
                {
                    "color": "#e9e6e2",
                    "zoom": 4
                },
                {
                    "color": "#e9e6e2",
                    "zoom": 5
                },
                {
                    "color": "#e9e6e2",
                    "zoom": 6
                },
                {
                    "color": "#e9e6e2",
                    "zoom": 7
                },
                {
                    "color": "#e9e6e2",
                    "zoom": 8
                },
                {
                    "color": "#e9e6e2",
                    "zoom": 9
                },
                {
                    "color": "#e9e6e2",
                    "zoom": 10
                },
                {
                    "color": "#e9e6e2",
                    "zoom": 11
                },
                {
                    "color": "#e9e6e2",
                    "zoom": 12
                },
                {
                    "color": "#e9e6e2",
                    "zoom": 13
                },
                {
                    "color": "#edebe8",
                    "zoom": 14
                },
                {
                    "color": "#f2f0ee",
                    "zoom": 15
                },
                {
                    "color": "#f3f1ef",
                    "zoom": 16
                },
                {
                    "color": "#f3f2f0",
                    "zoom": 17
                },
                {
                    "color": "#f4f2f0",
                    "zoom": 18
                },
                {
                    "color": "#f5f3f1",
                    "zoom": 19
                },
                {
                    "color": "#f5f4f2",
                    "zoom": 20
                },
                {
                    "color": "#f6f5f3",
                    "zoom": 21
                }
            ]
        },
        {
            "tags": {
                "any": "structure",
                "none": [
                    "building",
                    "fence"
                ]
            },
            "elements": "geometry",
            "stylers": [
                {
                    "opacity": 0.9
                },
                {
                    "color": "#e9e6e2",
                    "zoom": 0
                },
                {
                    "color": "#e9e6e2",
                    "zoom": 1
                },
                {
                    "color": "#e9e6e2",
                    "zoom": 2
                },
                {
                    "color": "#e9e6e2",
                    "zoom": 3
                },
                {
                    "color": "#e9e6e2",
                    "zoom": 4
                },
                {
                    "color": "#e9e6e2",
                    "zoom": 5
                },
                {
                    "color": "#e9e6e2",
                    "zoom": 6
                },
                {
                    "color": "#e9e6e2",
                    "zoom": 7
                },
                {
                    "color": "#e9e6e2",
                    "zoom": 8
                },
                {
                    "color": "#e9e6e2",
                    "zoom": 9
                },
                {
                    "color": "#e9e6e2",
                    "zoom": 10
                },
                {
                    "color": "#e9e6e2",
                    "zoom": 11
                },
                {
                    "color": "#e9e6e2",
                    "zoom": 12
                },
                {
                    "color": "#e9e6e2",
                    "zoom": 13
                },
                {
                    "color": "#edebe8",
                    "zoom": 14
                },
                {
                    "color": "#f2f0ee",
                    "zoom": 15
                },
                {
                    "color": "#f3f1ef",
                    "zoom": 16
                },
                {
                    "color": "#f3f2f0",
                    "zoom": 17
                },
                {
                    "color": "#f4f2f0",
                    "zoom": 18
                },
                {
                    "color": "#f5f3f1",
                    "zoom": 19
                },
                {
                    "color": "#f5f4f2",
                    "zoom": 20
                },
                {
                    "color": "#f6f5f3",
                    "zoom": 21
                }
            ]
        },
        {
            "tags": "building",
            "elements": "geometry.fill",
            "stylers": [
                {
                    "color": "#ded9d3"
                },
                {
                    "opacity": 0.7,
                    "zoom": 0
                },
                {
                    "opacity": 0.7,
                    "zoom": 1
                },
                {
                    "opacity": 0.7,
                    "zoom": 2
                },
                {
                    "opacity": 0.7,
                    "zoom": 3
                },
                {
                    "opacity": 0.7,
                    "zoom": 4
                },
                {
                    "opacity": 0.7,
                    "zoom": 5
                },
                {
                    "opacity": 0.7,
                    "zoom": 6
                },
                {
                    "opacity": 0.7,
                    "zoom": 7
                },
                {
                    "opacity": 0.7,
                    "zoom": 8
                },
                {
                    "opacity": 0.7,
                    "zoom": 9
                },
                {
                    "opacity": 0.7,
                    "zoom": 10
                },
                {
                    "opacity": 0.7,
                    "zoom": 11
                },
                {
                    "opacity": 0.7,
                    "zoom": 12
                },
                {
                    "opacity": 0.7,
                    "zoom": 13
                },
                {
                    "opacity": 0.7,
                    "zoom": 14
                },
                {
                    "opacity": 0.7,
                    "zoom": 15
                },
                {
                    "opacity": 0.9,
                    "zoom": 16
                },
                {
                    "opacity": 0.6,
                    "zoom": 17
                },
                {
                    "opacity": 0.6,
                    "zoom": 18
                },
                {
                    "opacity": 0.6,
                    "zoom": 19
                },
                {
                    "opacity": 0.6,
                    "zoom": 20
                },
                {
                    "opacity": 0.6,
                    "zoom": 21
                }
            ]
        },
        {
            "tags": "building",
            "elements": "geometry.outline",
            "stylers": [
                {
                    "color": "#ccc5bd"
                },
                {
                    "opacity": 0.5,
                    "zoom": 0
                },
                {
                    "opacity": 0.5,
                    "zoom": 1
                },
                {
                    "opacity": 0.5,
                    "zoom": 2
                },
                {
                    "opacity": 0.5,
                    "zoom": 3
                },
                {
                    "opacity": 0.5,
                    "zoom": 4
                },
                {
                    "opacity": 0.5,
                    "zoom": 5
                },
                {
                    "opacity": 0.5,
                    "zoom": 6
                },
                {
                    "opacity": 0.5,
                    "zoom": 7
                },
                {
                    "opacity": 0.5,
                    "zoom": 8
                },
                {
                    "opacity": 0.5,
                    "zoom": 9
                },
                {
                    "opacity": 0.5,
                    "zoom": 10
                },
                {
                    "opacity": 0.5,
                    "zoom": 11
                },
                {
                    "opacity": 0.5,
                    "zoom": 12
                },
                {
                    "opacity": 0.5,
                    "zoom": 13
                },
                {
                    "opacity": 0.5,
                    "zoom": 14
                },
                {
                    "opacity": 0.5,
                    "zoom": 15
                },
                {
                    "opacity": 0.5,
                    "zoom": 16
                },
                {
                    "opacity": 1,
                    "zoom": 17
                },
                {
                    "opacity": 1,
                    "zoom": 18
                },
                {
                    "opacity": 1,
                    "zoom": 19
                },
                {
                    "opacity": 1,
                    "zoom": 20
                },
                {
                    "opacity": 1,
                    "zoom": 21
                }
            ]
        },
        {
            "tags": {
                "any": "urban_area",
                "none": [
                    "residential",
                    "industrial",
                    "cemetery",
                    "park",
                    "medical",
                    "sports_ground",
                    "beach",
                    "construction_site"
                ]
            },
            "elements": "geometry",
            "stylers": [
                {
                    "color": "#e0dcd6",
                    "opacity": 1,
                    "zoom": 0
                },
                {
                    "color": "#e0dcd6",
                    "opacity": 1,
                    "zoom": 1
                },
                {
                    "color": "#e0dcd6",
                    "opacity": 1,
                    "zoom": 2
                },
                {
                    "color": "#e0dcd6",
                    "opacity": 1,
                    "zoom": 3
                },
                {
                    "color": "#e0dcd6",
                    "opacity": 1,
                    "zoom": 4
                },
                {
                    "color": "#e0dcd6",
                    "opacity": 1,
                    "zoom": 5
                },
                {
                    "color": "#e0dcd6",
                    "opacity": 1,
                    "zoom": 6
                },
                {
                    "color": "#e0dcd6",
                    "opacity": 1,
                    "zoom": 7
                },
                {
                    "color": "#e0dcd6",
                    "opacity": 1,
                    "zoom": 8
                },
                {
                    "color": "#e0dcd6",
                    "opacity": 1,
                    "zoom": 9
                },
                {
                    "color": "#e0dcd6",
                    "opacity": 1,
                    "zoom": 10
                },
                {
                    "color": "#e0dcd6",
                    "opacity": 1,
                    "zoom": 11
                },
                {
                    "color": "#e0dcd6",
                    "opacity": 1,
                    "zoom": 12
                },
                {
                    "color": "#e0dcd6",
                    "opacity": 1,
                    "zoom": 13
                },
                {
                    "color": "#e5e2dd",
                    "opacity": 1,
                    "zoom": 14
                },
                {
                    "color": "#ebe9e5",
                    "opacity": 1,
                    "zoom": 15
                },
                {
                    "color": "#f0efec",
                    "opacity": 0.67,
                    "zoom": 16
                },
                {
                    "color": "#f6f5f3",
                    "opacity": 0.33,
                    "zoom": 17
                },
                {
                    "color": "#f6f5f3",
                    "opacity": 0,
                    "zoom": 18
                },
                {
                    "color": "#f6f5f3",
                    "opacity": 0,
                    "zoom": 19
                },
                {
                    "color": "#f6f5f3",
                    "opacity": 0,
                    "zoom": 20
                },
                {
                    "color": "#f6f5f3",
                    "opacity": 0,
                    "zoom": 21
                }
            ]
        },
        {
            "tags": "poi",
            "elements": "label.icon",
            "stylers": [
                {
                    "color": "#e7e3df"
                },
                {
                    "secondary-color": "#ffffff"
                },
                {
                    "tertiary-color": "#ffffff"
                }
            ]
        },
        {
            "tags": "poi",
            "elements": "label.text.fill",
            "stylers": [
                {
                    "color": "#574d42"
                }
            ]
        },
        {
            "tags": "poi",
            "elements": "label.text.outline",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "opacity": 0.5
                }
            ]
        },
        {
            "tags": "outdoor",
            "elements": "label.icon",
            "stylers": [
                {
                    "color": "#e7e3df"
                },
                {
                    "secondary-color": "#ffffff"
                },
                {
                    "tertiary-color": "#ffffff"
                }
            ]
        },
        {
            "tags": "outdoor",
            "elements": "label.text.fill",
            "stylers": [
                {
                    "color": "#574d42"
                }
            ]
        },
        {
            "tags": "outdoor",
            "elements": "label.text.outline",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "opacity": 0.5
                }
            ]
        },
        {
            "tags": "park",
            "elements": "label.icon",
            "stylers": [
                {
                    "color": "#e7e3df"
                },
                {
                    "secondary-color": "#ffffff"
                },
                {
                    "tertiary-color": "#ffffff"
                }
            ]
        },
        {
            "tags": "park",
            "elements": "label.text.fill",
            "stylers": [
                {
                    "color": "#574d42"
                }
            ]
        },
        {
            "tags": "park",
            "elements": "label.text.outline",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "opacity": 0.5
                }
            ]
        },
        {
            "tags": "cemetery",
            "elements": "label.icon",
            "stylers": [
                {
                    "color": "#e7e3df"
                },
                {
                    "secondary-color": "#ffffff"
                },
                {
                    "tertiary-color": "#ffffff"
                }
            ]
        },
        {
            "tags": "cemetery",
            "elements": "label.text.fill",
            "stylers": [
                {
                    "color": "#574d42"
                }
            ]
        },
        {
            "tags": "cemetery",
            "elements": "label.text.outline",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "opacity": 0.5
                }
            ]
        },
        {
            "tags": "beach",
            "elements": "label.icon",
            "stylers": [
                {
                    "color": "#e7e3df"
                },
                {
                    "secondary-color": "#ffffff"
                },
                {
                    "tertiary-color": "#ffffff"
                }
            ]
        },
        {
            "tags": "beach",
            "elements": "label.text.fill",
            "stylers": [
                {
                    "color": "#574d42"
                }
            ]
        },
        {
            "tags": "beach",
            "elements": "label.text.outline",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "opacity": 0.5
                }
            ]
        },
        {
            "tags": "medical",
            "elements": "label.icon",
            "stylers": [
                {
                    "color": "#e7e3df"
                },
                {
                    "secondary-color": "#ffffff"
                },
                {
                    "tertiary-color": "#ffffff"
                }
            ]
        },
        {
            "tags": "medical",
            "elements": "label.text.fill",
            "stylers": [
                {
                    "color": "#574d42"
                }
            ]
        },
        {
            "tags": "medical",
            "elements": "label.text.outline",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "opacity": 0.5
                }
            ]
        },
        {
            "tags": "shopping",
            "elements": "label.icon",
            "stylers": [
                {
                    "color": "#e7e3df"
                },
                {
                    "secondary-color": "#ffffff"
                },
                {
                    "tertiary-color": "#ffffff"
                }
            ]
        },
        {
            "tags": "shopping",
            "elements": "label.text.fill",
            "stylers": [
                {
                    "color": "#574d42"
                }
            ]
        },
        {
            "tags": "shopping",
            "elements": "label.text.outline",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "opacity": 0.5
                }
            ]
        },
        {
            "tags": "commercial_services",
            "elements": "label.icon",
            "stylers": [
                {
                    "color": "#e7e3df"
                },
                {
                    "secondary-color": "#ffffff"
                },
                {
                    "tertiary-color": "#ffffff"
                }
            ]
        },
        {
            "tags": "commercial_services",
            "elements": "label.text.fill",
            "stylers": [
                {
                    "color": "#574d42"
                }
            ]
        },
        {
            "tags": "commercial_services",
            "elements": "label.text.outline",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "opacity": 0.5
                }
            ]
        },
        {
            "tags": "food_and_drink",
            "elements": "label.icon",
            "stylers": [
                {
                    "color": "#e7e3df"
                },
                {
                    "secondary-color": "#ffffff"
                },
                {
                    "tertiary-color": "#ffffff"
                }
            ]
        },
        {
            "tags": "food_and_drink",
            "elements": "label.text.fill",
            "stylers": [
                {
                    "color": "#574d42"
                }
            ]
        },
        {
            "tags": "food_and_drink",
            "elements": "label.text.outline",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "opacity": 0.5
                }
            ]
        },
        {
            "tags": "road",
            "elements": "label.icon",
            "types": "point",
            "stylers": [
                {
                    "color": "#e7e3df"
                },
                {
                    "secondary-color": "#ffffff"
                },
                {
                    "tertiary-color": "#ffffff"
                }
            ]
        },
        {
            "tags": "road",
            "elements": "label.text.fill",
            "types": "point",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "tags": "entrance",
            "elements": "label.icon",
            "stylers": [
                {
                    "color": "#e7e3df"
                },
                {
                    "secondary-color": "#ffffff"
                },
                {
                    "hue": "#e7e3df"
                }
            ]
        },
        {
            "tags": "locality",
            "elements": "label.icon",
            "stylers": [
                {
                    "color": "#e7e3df"
                },
                {
                    "secondary-color": "#ffffff"
                }
            ]
        },
        {
            "tags": "country",
            "elements": "label.text.fill",
            "stylers": [
                {
                    "opacity": 0.8
                },
                {
                    "color": "#837363"
                }
            ]
        },
        {
            "tags": "country",
            "elements": "label.text.outline",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "opacity": 0.5
                }
            ]
        },
        {
            "tags": "region",
            "elements": "label.text.fill",
            "stylers": [
                {
                    "color": "#837363"
                },
                {
                    "opacity": 0.8
                }
            ]
        },
        {
            "tags": "region",
            "elements": "label.text.outline",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "opacity": 0.5
                }
            ]
        },
        {
            "tags": "district",
            "elements": "label.text.fill",
            "stylers": [
                {
                    "color": "#837363"
                },
                {
                    "opacity": 0.8
                }
            ]
        },
        {
            "tags": "district",
            "elements": "label.text.outline",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "opacity": 0.5
                }
            ]
        },
        {
            "tags": {
                "any": "admin",
                "none": [
                    "country",
                    "region",
                    "locality",
                    "district",
                    "address"
                ]
            },
            "elements": "label.text.fill",
            "stylers": [
                {
                    "color": "#837363"
                }
            ]
        },
        {
            "tags": {
                "any": "admin",
                "none": [
                    "country",
                    "region",
                    "locality",
                    "district",
                    "address"
                ]
            },
            "elements": "label.text.outline",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "opacity": 0.5
                }
            ]
        },
        {
            "tags": "locality",
            "elements": "label.text.fill",
            "stylers": [
                {
                    "color": "#574d42",
                    "zoom": 0
                },
                {
                    "color": "#574d42",
                    "zoom": 1
                },
                {
                    "color": "#574d42",
                    "zoom": 2
                },
                {
                    "color": "#574d42",
                    "zoom": 3
                },
                {
                    "color": "#574d42",
                    "zoom": 4
                },
                {
                    "color": "#554b40",
                    "zoom": 5
                },
                {
                    "color": "#52493e",
                    "zoom": 6
                },
                {
                    "color": "#50473d",
                    "zoom": 7
                },
                {
                    "color": "#4e443b",
                    "zoom": 8
                },
                {
                    "color": "#4b4239",
                    "zoom": 9
                },
                {
                    "color": "#494037",
                    "zoom": 10
                },
                {
                    "color": "#494037",
                    "zoom": 11
                },
                {
                    "color": "#494037",
                    "zoom": 12
                },
                {
                    "color": "#494037",
                    "zoom": 13
                },
                {
                    "color": "#494037",
                    "zoom": 14
                },
                {
                    "color": "#494037",
                    "zoom": 15
                },
                {
                    "color": "#494037",
                    "zoom": 16
                },
                {
                    "color": "#494037",
                    "zoom": 17
                },
                {
                    "color": "#494037",
                    "zoom": 18
                },
                {
                    "color": "#494037",
                    "zoom": 19
                },
                {
                    "color": "#494037",
                    "zoom": 20
                },
                {
                    "color": "#494037",
                    "zoom": 21
                }
            ]
        },
        {
            "tags": "locality",
            "elements": "label.text.outline",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "opacity": 0.5
                }
            ]
        },
        {
            "tags": "road",
            "elements": "label.text.fill",
            "types": "polyline",
            "stylers": [
                {
                    "color": "#66594d"
                }
            ]
        },
        {
            "tags": "road",
            "elements": "label.text.outline",
            "types": "polyline",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "opacity": 0.5
                }
            ]
        },
        {
            "tags": "road",
            "elements": "geometry.fill.pattern",
            "types": "polyline",
            "stylers": [
                {
                    "scale": 1
                },
                {
                    "color": "#9c8c7c"
                }
            ]
        },
        {
            "tags": "road",
            "elements": "label.text.fill",
            "types": "point",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "tags": "structure",
            "elements": "label.text.fill",
            "stylers": [
                {
                    "color": "#746858"
                },
                {
                    "opacity": 0.5
                }
            ]
        },
        {
            "tags": "structure",
            "elements": "label.text.outline",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "opacity": 0.5
                }
            ]
        },
        {
            "tags": "address",
            "elements": "label.text.fill",
            "stylers": [
                {
                    "color": "#746858"
                },
                {
                    "opacity": 0.9,
                    "zoom": 0
                },
                {
                    "opacity": 0.9,
                    "zoom": 1
                },
                {
                    "opacity": 0.9,
                    "zoom": 2
                },
                {
                    "opacity": 0.9,
                    "zoom": 3
                },
                {
                    "opacity": 0.9,
                    "zoom": 4
                },
                {
                    "opacity": 0.9,
                    "zoom": 5
                },
                {
                    "opacity": 0.9,
                    "zoom": 6
                },
                {
                    "opacity": 0.9,
                    "zoom": 7
                },
                {
                    "opacity": 0.9,
                    "zoom": 8
                },
                {
                    "opacity": 0.9,
                    "zoom": 9
                },
                {
                    "opacity": 0.9,
                    "zoom": 10
                },
                {
                    "opacity": 0.9,
                    "zoom": 11
                },
                {
                    "opacity": 0.9,
                    "zoom": 12
                },
                {
                    "opacity": 0.9,
                    "zoom": 13
                },
                {
                    "opacity": 0.9,
                    "zoom": 14
                },
                {
                    "opacity": 0.9,
                    "zoom": 15
                },
                {
                    "opacity": 0.9,
                    "zoom": 16
                },
                {
                    "opacity": 1,
                    "zoom": 17
                },
                {
                    "opacity": 1,
                    "zoom": 18
                },
                {
                    "opacity": 1,
                    "zoom": 19
                },
                {
                    "opacity": 1,
                    "zoom": 20
                },
                {
                    "opacity": 1,
                    "zoom": 21
                }
            ]
        },
        {
            "tags": "address",
            "elements": "label.text.outline",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "opacity": 0.5
                }
            ]
        },
        {
            "tags": "landscape",
            "elements": "label.text.fill",
            "stylers": [
                {
                    "color": "#837363",
                    "opacity": 1,
                    "zoom": 0
                },
                {
                    "color": "#837363",
                    "opacity": 1,
                    "zoom": 1
                },
                {
                    "color": "#837363",
                    "opacity": 1,
                    "zoom": 2
                },
                {
                    "color": "#837363",
                    "opacity": 1,
                    "zoom": 3
                },
                {
                    "color": "#746858",
                    "opacity": 0.5,
                    "zoom": 4
                },
                {
                    "color": "#746858",
                    "opacity": 0.5,
                    "zoom": 5
                },
                {
                    "color": "#746858",
                    "opacity": 0.5,
                    "zoom": 6
                },
                {
                    "color": "#746858",
                    "opacity": 0.5,
                    "zoom": 7
                },
                {
                    "color": "#746858",
                    "opacity": 0.5,
                    "zoom": 8
                },
                {
                    "color": "#746858",
                    "opacity": 0.5,
                    "zoom": 9
                },
                {
                    "color": "#746858",
                    "opacity": 0.5,
                    "zoom": 10
                },
                {
                    "color": "#746858",
                    "opacity": 0.5,
                    "zoom": 11
                },
                {
                    "color": "#746858",
                    "opacity": 0.5,
                    "zoom": 12
                },
                {
                    "color": "#746858",
                    "opacity": 0.5,
                    "zoom": 13
                },
                {
                    "color": "#746858",
                    "opacity": 0.5,
                    "zoom": 14
                },
                {
                    "color": "#746858",
                    "opacity": 0.5,
                    "zoom": 15
                },
                {
                    "color": "#746858",
                    "opacity": 0.5,
                    "zoom": 16
                },
                {
                    "color": "#746858",
                    "opacity": 0.5,
                    "zoom": 17
                },
                {
                    "color": "#746858",
                    "opacity": 0.5,
                    "zoom": 18
                },
                {
                    "color": "#746858",
                    "opacity": 0.5,
                    "zoom": 19
                },
                {
                    "color": "#746858",
                    "opacity": 0.5,
                    "zoom": 20
                },
                {
                    "color": "#746858",
                    "opacity": 0.5,
                    "zoom": 21
                }
            ]
        },
        {
            "tags": "landscape",
            "elements": "label.text.outline",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "opacity": 0.5,
                    "zoom": 0
                },
                {
                    "opacity": 0.5,
                    "zoom": 1
                },
                {
                    "opacity": 0.5,
                    "zoom": 2
                },
                {
                    "opacity": 0.5,
                    "zoom": 3
                },
                {
                    "opacity": 0,
                    "zoom": 4
                },
                {
                    "opacity": 0,
                    "zoom": 5
                },
                {
                    "opacity": 0,
                    "zoom": 6
                },
                {
                    "opacity": 0,
                    "zoom": 7
                },
                {
                    "opacity": 0,
                    "zoom": 8
                },
                {
                    "opacity": 0,
                    "zoom": 9
                },
                {
                    "opacity": 0,
                    "zoom": 10
                },
                {
                    "opacity": 0,
                    "zoom": 11
                },
                {
                    "opacity": 0,
                    "zoom": 12
                },
                {
                    "opacity": 0,
                    "zoom": 13
                },
                {
                    "opacity": 0,
                    "zoom": 14
                },
                {
                    "opacity": 0,
                    "zoom": 15
                },
                {
                    "opacity": 0,
                    "zoom": 16
                },
                {
                    "opacity": 0,
                    "zoom": 17
                },
                {
                    "opacity": 0,
                    "zoom": 18
                },
                {
                    "opacity": 0,
                    "zoom": 19
                },
                {
                    "opacity": 0,
                    "zoom": 20
                },
                {
                    "opacity": 0,
                    "zoom": 21
                }
            ]
        },
        {
            "tags": "water",
            "elements": "label.text.fill",
            "stylers": [
                {
                    "color": "#716356"
                },
                {
                    "opacity": 0.8
                }
            ]
        },
        {
            "tags": "water",
            "elements": "label.text.outline",
            "types": "polyline",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "opacity": 0.2
                }
            ]
        },
        {
            "tags": {
                "any": "road_1",
                "none": "is_tunnel"
            },
            "elements": "geometry.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "scale": 0,
                    "zoom": 0
                },
                {
                    "scale": 0,
                    "zoom": 1
                },
                {
                    "scale": 0,
                    "zoom": 2
                },
                {
                    "scale": 0,
                    "zoom": 3
                },
                {
                    "scale": 0,
                    "zoom": 4
                },
                {
                    "scale": 0,
                    "zoom": 5
                },
                {
                    "scale": 2.97,
                    "zoom": 6
                },
                {
                    "scale": 3.19,
                    "zoom": 7
                },
                {
                    "scale": 3.53,
                    "zoom": 8
                },
                {
                    "scale": 4,
                    "zoom": 9
                },
                {
                    "scale": 3.61,
                    "zoom": 10
                },
                {
                    "scale": 3.06,
                    "zoom": 11
                },
                {
                    "scale": 2.64,
                    "zoom": 12
                },
                {
                    "scale": 2.27,
                    "zoom": 13
                },
                {
                    "scale": 2.03,
                    "zoom": 14
                },
                {
                    "scale": 1.9,
                    "zoom": 15
                },
                {
                    "scale": 1.86,
                    "zoom": 16
                },
                {
                    "scale": 1.48,
                    "zoom": 17
                },
                {
                    "scale": 1.21,
                    "zoom": 18
                },
                {
                    "scale": 1.04,
                    "zoom": 19
                },
                {
                    "scale": 0.94,
                    "zoom": 20
                },
                {
                    "scale": 0.9,
                    "zoom": 21
                }
            ]
        },
        {
            "tags": {
                "any": "road_1"
            },
            "elements": "geometry.outline",
            "stylers": [
                {
                    "color": "#00000000",
                    "scale": 1.4,
                    "zoom": 0
                },
                {
                    "color": "#00000000",
                    "scale": 1.4,
                    "zoom": 1
                },
                {
                    "color": "#00000000",
                    "scale": 1.4,
                    "zoom": 2
                },
                {
                    "color": "#00000000",
                    "scale": 1.4,
                    "zoom": 3
                },
                {
                    "color": "#00000000",
                    "scale": 1.4,
                    "zoom": 4
                },
                {
                    "color": "#00000000",
                    "scale": 1.4,
                    "zoom": 5
                },
                {
                    "color": "#00000000",
                    "scale": 3.05,
                    "zoom": 6
                },
                {
                    "color": "#00000000",
                    "scale": 3.05,
                    "zoom": 7
                },
                {
                    "color": "#e5e1dc",
                    "scale": 3.15,
                    "zoom": 8
                },
                {
                    "color": "#e9e6e2",
                    "scale": 3.37,
                    "zoom": 9
                },
                {
                    "color": "#e9e6e2",
                    "scale": 3.36,
                    "zoom": 10
                },
                {
                    "color": "#e9e6e2",
                    "scale": 3.17,
                    "zoom": 11
                },
                {
                    "color": "#e9e6e2",
                    "scale": 3,
                    "zoom": 12
                },
                {
                    "color": "#e9e6e2",
                    "scale": 2.8,
                    "zoom": 13
                },
                {
                    "color": "#edebe8",
                    "scale": 2.66,
                    "zoom": 14
                },
                {
                    "color": "#edebe8",
                    "scale": 2.61,
                    "zoom": 15
                },
                {
                    "color": "#efedea",
                    "scale": 2.64,
                    "zoom": 16
                },
                {
                    "color": "#f0eeec",
                    "scale": 2.14,
                    "zoom": 17
                },
                {
                    "color": "#f2f0ee",
                    "scale": 1.79,
                    "zoom": 18
                },
                {
                    "color": "#f4f2f0",
                    "scale": 1.55,
                    "zoom": 19
                },
                {
                    "color": "#f5f3f1",
                    "scale": 1.41,
                    "zoom": 20
                },
                {
                    "color": "#f6f5f3",
                    "scale": 1.35,
                    "zoom": 21
                }
            ]
        },
        {
            "tags": {
                "any": "road_2",
                "none": "is_tunnel"
            },
            "elements": "geometry.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "scale": 0,
                    "zoom": 0
                },
                {
                    "scale": 0,
                    "zoom": 1
                },
                {
                    "scale": 0,
                    "zoom": 2
                },
                {
                    "scale": 0,
                    "zoom": 3
                },
                {
                    "scale": 0,
                    "zoom": 4
                },
                {
                    "scale": 0,
                    "zoom": 5
                },
                {
                    "scale": 2.97,
                    "zoom": 6
                },
                {
                    "scale": 3.19,
                    "zoom": 7
                },
                {
                    "scale": 3.53,
                    "zoom": 8
                },
                {
                    "scale": 4,
                    "zoom": 9
                },
                {
                    "scale": 3.61,
                    "zoom": 10
                },
                {
                    "scale": 3.06,
                    "zoom": 11
                },
                {
                    "scale": 2.64,
                    "zoom": 12
                },
                {
                    "scale": 2.27,
                    "zoom": 13
                },
                {
                    "scale": 2.03,
                    "zoom": 14
                },
                {
                    "scale": 1.9,
                    "zoom": 15
                },
                {
                    "scale": 1.86,
                    "zoom": 16
                },
                {
                    "scale": 1.48,
                    "zoom": 17
                },
                {
                    "scale": 1.21,
                    "zoom": 18
                },
                {
                    "scale": 1.04,
                    "zoom": 19
                },
                {
                    "scale": 0.94,
                    "zoom": 20
                },
                {
                    "scale": 0.9,
                    "zoom": 21
                }
            ]
        },
        {
            "tags": {
                "any": "road_2"
            },
            "elements": "geometry.outline",
            "stylers": [
                {
                    "color": "#00000000",
                    "scale": 1.4,
                    "zoom": 0
                },
                {
                    "color": "#00000000",
                    "scale": 1.4,
                    "zoom": 1
                },
                {
                    "color": "#00000000",
                    "scale": 1.4,
                    "zoom": 2
                },
                {
                    "color": "#00000000",
                    "scale": 1.4,
                    "zoom": 3
                },
                {
                    "color": "#00000000",
                    "scale": 1.4,
                    "zoom": 4
                },
                {
                    "color": "#00000000",
                    "scale": 1.4,
                    "zoom": 5
                },
                {
                    "color": "#00000000",
                    "scale": 3.05,
                    "zoom": 6
                },
                {
                    "color": "#00000000",
                    "scale": 3.05,
                    "zoom": 7
                },
                {
                    "color": "#e5e1dc",
                    "scale": 3.15,
                    "zoom": 8
                },
                {
                    "color": "#e9e6e2",
                    "scale": 3.37,
                    "zoom": 9
                },
                {
                    "color": "#e9e6e2",
                    "scale": 3.36,
                    "zoom": 10
                },
                {
                    "color": "#e9e6e2",
                    "scale": 3.17,
                    "zoom": 11
                },
                {
                    "color": "#e9e6e2",
                    "scale": 3,
                    "zoom": 12
                },
                {
                    "color": "#e9e6e2",
                    "scale": 2.8,
                    "zoom": 13
                },
                {
                    "color": "#edebe8",
                    "scale": 2.66,
                    "zoom": 14
                },
                {
                    "color": "#edebe8",
                    "scale": 2.61,
                    "zoom": 15
                },
                {
                    "color": "#efedea",
                    "scale": 2.64,
                    "zoom": 16
                },
                {
                    "color": "#f0eeec",
                    "scale": 2.14,
                    "zoom": 17
                },
                {
                    "color": "#f2f0ee",
                    "scale": 1.79,
                    "zoom": 18
                },
                {
                    "color": "#f4f2f0",
                    "scale": 1.55,
                    "zoom": 19
                },
                {
                    "color": "#f5f3f1",
                    "scale": 1.41,
                    "zoom": 20
                },
                {
                    "color": "#f6f5f3",
                    "scale": 1.35,
                    "zoom": 21
                }
            ]
        },
        {
            "tags": {
                "any": "road_3",
                "none": "is_tunnel"
            },
            "elements": "geometry.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "scale": 0,
                    "zoom": 0
                },
                {
                    "scale": 0,
                    "zoom": 1
                },
                {
                    "scale": 0,
                    "zoom": 2
                },
                {
                    "scale": 0,
                    "zoom": 3
                },
                {
                    "scale": 0,
                    "zoom": 4
                },
                {
                    "scale": 0,
                    "zoom": 5
                },
                {
                    "scale": 0,
                    "zoom": 6
                },
                {
                    "scale": 0,
                    "zoom": 7
                },
                {
                    "scale": 0,
                    "zoom": 8
                },
                {
                    "scale": 2.51,
                    "zoom": 9
                },
                {
                    "scale": 2.62,
                    "zoom": 10
                },
                {
                    "scale": 1.68,
                    "zoom": 11
                },
                {
                    "scale": 1.67,
                    "zoom": 12
                },
                {
                    "scale": 1.38,
                    "zoom": 13
                },
                {
                    "scale": 1.19,
                    "zoom": 14
                },
                {
                    "scale": 1.08,
                    "zoom": 15
                },
                {
                    "scale": 1.04,
                    "zoom": 16
                },
                {
                    "scale": 0.91,
                    "zoom": 17
                },
                {
                    "scale": 0.84,
                    "zoom": 18
                },
                {
                    "scale": 0.82,
                    "zoom": 19
                },
                {
                    "scale": 0.84,
                    "zoom": 20
                },
                {
                    "scale": 0.9,
                    "zoom": 21
                }
            ]
        },
        {
            "tags": {
                "any": "road_3"
            },
            "elements": "geometry.outline",
            "stylers": [
                {
                    "color": "#ffffff",
                    "scale": 1.6,
                    "zoom": 0
                },
                {
                    "color": "#ffffff",
                    "scale": 1.6,
                    "zoom": 1
                },
                {
                    "color": "#ffffff",
                    "scale": 1.6,
                    "zoom": 2
                },
                {
                    "color": "#ffffff",
                    "scale": 1.6,
                    "zoom": 3
                },
                {
                    "color": "#ffffff",
                    "scale": 1.6,
                    "zoom": 4
                },
                {
                    "color": "#ffffff",
                    "scale": 1.6,
                    "zoom": 5
                },
                {
                    "color": "#ffffff",
                    "scale": 1.6,
                    "zoom": 6
                },
                {
                    "color": "#ffffff",
                    "scale": 1.6,
                    "zoom": 7
                },
                {
                    "color": "#ffffff",
                    "scale": 1.29,
                    "zoom": 8
                },
                {
                    "color": "#e9e6e2",
                    "scale": 4.21,
                    "zoom": 9
                },
                {
                    "color": "#e9e6e2",
                    "scale": 2.74,
                    "zoom": 10
                },
                {
                    "color": "#e9e6e2",
                    "scale": 2.04,
                    "zoom": 11
                },
                {
                    "color": "#e9e6e2",
                    "scale": 2.13,
                    "zoom": 12
                },
                {
                    "color": "#e9e6e2",
                    "scale": 1.88,
                    "zoom": 13
                },
                {
                    "color": "#edebe8",
                    "scale": 1.7,
                    "zoom": 14
                },
                {
                    "color": "#edebe8",
                    "scale": 1.59,
                    "zoom": 15
                },
                {
                    "color": "#efedea",
                    "scale": 1.55,
                    "zoom": 16
                },
                {
                    "color": "#f0eeec",
                    "scale": 1.37,
                    "zoom": 17
                },
                {
                    "color": "#f2f0ee",
                    "scale": 1.27,
                    "zoom": 18
                },
                {
                    "color": "#f4f2f0",
                    "scale": 1.23,
                    "zoom": 19
                },
                {
                    "color": "#f5f3f1",
                    "scale": 1.26,
                    "zoom": 20
                },
                {
                    "color": "#f6f5f3",
                    "scale": 1.35,
                    "zoom": 21
                }
            ]
        },
        {
            "tags": {
                "any": "road_4",
                "none": "is_tunnel"
            },
            "elements": "geometry.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "scale": 0,
                    "zoom": 0
                },
                {
                    "scale": 0,
                    "zoom": 1
                },
                {
                    "scale": 0,
                    "zoom": 2
                },
                {
                    "scale": 0,
                    "zoom": 3
                },
                {
                    "scale": 0,
                    "zoom": 4
                },
                {
                    "scale": 0,
                    "zoom": 5
                },
                {
                    "scale": 0,
                    "zoom": 6
                },
                {
                    "scale": 0,
                    "zoom": 7
                },
                {
                    "scale": 0,
                    "zoom": 8
                },
                {
                    "scale": 0,
                    "zoom": 9
                },
                {
                    "scale": 1.69,
                    "zoom": 10
                },
                {
                    "scale": 1.26,
                    "zoom": 11
                },
                {
                    "scale": 1.41,
                    "zoom": 12
                },
                {
                    "scale": 1.19,
                    "zoom": 13
                },
                {
                    "scale": 1.04,
                    "zoom": 14
                },
                {
                    "scale": 0.97,
                    "zoom": 15
                },
                {
                    "scale": 1.15,
                    "zoom": 16
                },
                {
                    "scale": 0.99,
                    "zoom": 17
                },
                {
                    "scale": 0.89,
                    "zoom": 18
                },
                {
                    "scale": 0.85,
                    "zoom": 19
                },
                {
                    "scale": 0.85,
                    "zoom": 20
                },
                {
                    "scale": 0.9,
                    "zoom": 21
                }
            ]
        },
        {
            "tags": {
                "any": "road_4"
            },
            "elements": "geometry.outline",
            "stylers": [
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 0
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 1
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 2
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 3
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 4
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 5
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 6
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 7
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 8
                },
                {
                    "color": "#ffffff",
                    "scale": 1.12,
                    "zoom": 9
                },
                {
                    "color": "#e9e6e2",
                    "scale": 1.9,
                    "zoom": 10
                },
                {
                    "color": "#e9e6e2",
                    "scale": 1.62,
                    "zoom": 11
                },
                {
                    "color": "#e9e6e2",
                    "scale": 1.83,
                    "zoom": 12
                },
                {
                    "color": "#e9e6e2",
                    "scale": 1.64,
                    "zoom": 13
                },
                {
                    "color": "#edebe8",
                    "scale": 1.51,
                    "zoom": 14
                },
                {
                    "color": "#edebe8",
                    "scale": 1.44,
                    "zoom": 15
                },
                {
                    "color": "#efedea",
                    "scale": 1.69,
                    "zoom": 16
                },
                {
                    "color": "#f0eeec",
                    "scale": 1.47,
                    "zoom": 17
                },
                {
                    "color": "#f2f0ee",
                    "scale": 1.34,
                    "zoom": 18
                },
                {
                    "color": "#f4f2f0",
                    "scale": 1.28,
                    "zoom": 19
                },
                {
                    "color": "#f5f3f1",
                    "scale": 1.28,
                    "zoom": 20
                },
                {
                    "color": "#f6f5f3",
                    "scale": 1.34,
                    "zoom": 21
                }
            ]
        },
        {
            "tags": {
                "any": "road_5",
                "none": "is_tunnel"
            },
            "elements": "geometry.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "scale": 0,
                    "zoom": 0
                },
                {
                    "scale": 0,
                    "zoom": 1
                },
                {
                    "scale": 0,
                    "zoom": 2
                },
                {
                    "scale": 0,
                    "zoom": 3
                },
                {
                    "scale": 0,
                    "zoom": 4
                },
                {
                    "scale": 0,
                    "zoom": 5
                },
                {
                    "scale": 0,
                    "zoom": 6
                },
                {
                    "scale": 0,
                    "zoom": 7
                },
                {
                    "scale": 0,
                    "zoom": 8
                },
                {
                    "scale": 0,
                    "zoom": 9
                },
                {
                    "scale": 0,
                    "zoom": 10
                },
                {
                    "scale": 0,
                    "zoom": 11
                },
                {
                    "scale": 1.25,
                    "zoom": 12
                },
                {
                    "scale": 0.95,
                    "zoom": 13
                },
                {
                    "scale": 0.81,
                    "zoom": 14
                },
                {
                    "scale": 0.95,
                    "zoom": 15
                },
                {
                    "scale": 1.1,
                    "zoom": 16
                },
                {
                    "scale": 0.93,
                    "zoom": 17
                },
                {
                    "scale": 0.85,
                    "zoom": 18
                },
                {
                    "scale": 0.82,
                    "zoom": 19
                },
                {
                    "scale": 0.84,
                    "zoom": 20
                },
                {
                    "scale": 0.9,
                    "zoom": 21
                }
            ]
        },
        {
            "tags": {
                "any": "road_5"
            },
            "elements": "geometry.outline",
            "stylers": [
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 0
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 1
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 2
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 3
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 4
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 5
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 6
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 7
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 8
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 9
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 10
                },
                {
                    "color": "#ffffff",
                    "scale": 0.62,
                    "zoom": 11
                },
                {
                    "color": "#e9e6e2",
                    "scale": 1.61,
                    "zoom": 12
                },
                {
                    "color": "#e9e6e2",
                    "scale": 1.36,
                    "zoom": 13
                },
                {
                    "color": "#edebe8",
                    "scale": 1.22,
                    "zoom": 14
                },
                {
                    "color": "#edebe8",
                    "scale": 1.41,
                    "zoom": 15
                },
                {
                    "color": "#efedea",
                    "scale": 1.63,
                    "zoom": 16
                },
                {
                    "color": "#f0eeec",
                    "scale": 1.4,
                    "zoom": 17
                },
                {
                    "color": "#f2f0ee",
                    "scale": 1.27,
                    "zoom": 18
                },
                {
                    "color": "#f4f2f0",
                    "scale": 1.23,
                    "zoom": 19
                },
                {
                    "color": "#f5f3f1",
                    "scale": 1.25,
                    "zoom": 20
                },
                {
                    "color": "#f6f5f3",
                    "scale": 1.34,
                    "zoom": 21
                }
            ]
        },
        {
            "tags": {
                "any": "road_6",
                "none": "is_tunnel"
            },
            "elements": "geometry.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "scale": 0,
                    "zoom": 0
                },
                {
                    "scale": 0,
                    "zoom": 1
                },
                {
                    "scale": 0,
                    "zoom": 2
                },
                {
                    "scale": 0,
                    "zoom": 3
                },
                {
                    "scale": 0,
                    "zoom": 4
                },
                {
                    "scale": 0,
                    "zoom": 5
                },
                {
                    "scale": 0,
                    "zoom": 6
                },
                {
                    "scale": 0,
                    "zoom": 7
                },
                {
                    "scale": 0,
                    "zoom": 8
                },
                {
                    "scale": 0,
                    "zoom": 9
                },
                {
                    "scale": 0,
                    "zoom": 10
                },
                {
                    "scale": 0,
                    "zoom": 11
                },
                {
                    "scale": 0,
                    "zoom": 12
                },
                {
                    "scale": 2.25,
                    "zoom": 13
                },
                {
                    "scale": 1.27,
                    "zoom": 14
                },
                {
                    "scale": 1.25,
                    "zoom": 15
                },
                {
                    "scale": 1.31,
                    "zoom": 16
                },
                {
                    "scale": 1.04,
                    "zoom": 17
                },
                {
                    "scale": 0.9,
                    "zoom": 18
                },
                {
                    "scale": 0.85,
                    "zoom": 19
                },
                {
                    "scale": 0.85,
                    "zoom": 20
                },
                {
                    "scale": 0.9,
                    "zoom": 21
                }
            ]
        },
        {
            "tags": {
                "any": "road_6"
            },
            "elements": "geometry.outline",
            "stylers": [
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 0
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 1
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 2
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 3
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 4
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 5
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 6
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 7
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 8
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 9
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 10
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 11
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 12
                },
                {
                    "color": "#e9e6e2",
                    "scale": 2.31,
                    "zoom": 13
                },
                {
                    "color": "#edebe8",
                    "scale": 1.7,
                    "zoom": 14
                },
                {
                    "color": "#edebe8",
                    "scale": 1.76,
                    "zoom": 15
                },
                {
                    "color": "#efedea",
                    "scale": 1.89,
                    "zoom": 16
                },
                {
                    "color": "#f0eeec",
                    "scale": 1.55,
                    "zoom": 17
                },
                {
                    "color": "#f2f0ee",
                    "scale": 1.36,
                    "zoom": 18
                },
                {
                    "color": "#f4f2f0",
                    "scale": 1.27,
                    "zoom": 19
                },
                {
                    "color": "#f5f3f1",
                    "scale": 1.27,
                    "zoom": 20
                },
                {
                    "color": "#f6f5f3",
                    "scale": 1.34,
                    "zoom": 21
                }
            ]
        },
        {
            "tags": {
                "any": "road_7",
                "none": "is_tunnel"
            },
            "elements": "geometry.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "scale": 0,
                    "zoom": 0
                },
                {
                    "scale": 0,
                    "zoom": 1
                },
                {
                    "scale": 0,
                    "zoom": 2
                },
                {
                    "scale": 0,
                    "zoom": 3
                },
                {
                    "scale": 0,
                    "zoom": 4
                },
                {
                    "scale": 0,
                    "zoom": 5
                },
                {
                    "scale": 0,
                    "zoom": 6
                },
                {
                    "scale": 0,
                    "zoom": 7
                },
                {
                    "scale": 0,
                    "zoom": 8
                },
                {
                    "scale": 0,
                    "zoom": 9
                },
                {
                    "scale": 0,
                    "zoom": 10
                },
                {
                    "scale": 0,
                    "zoom": 11
                },
                {
                    "scale": 0,
                    "zoom": 12
                },
                {
                    "scale": 0,
                    "zoom": 13
                },
                {
                    "scale": 0.9,
                    "zoom": 14
                },
                {
                    "scale": 0.78,
                    "zoom": 15
                },
                {
                    "scale": 0.88,
                    "zoom": 16
                },
                {
                    "scale": 0.8,
                    "zoom": 17
                },
                {
                    "scale": 0.78,
                    "zoom": 18
                },
                {
                    "scale": 0.79,
                    "zoom": 19
                },
                {
                    "scale": 0.83,
                    "zoom": 20
                },
                {
                    "scale": 0.9,
                    "zoom": 21
                }
            ]
        },
        {
            "tags": {
                "any": "road_7"
            },
            "elements": "geometry.outline",
            "stylers": [
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 0
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 1
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 2
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 3
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 4
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 5
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 6
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 7
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 8
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 9
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 10
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 11
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 12
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 13
                },
                {
                    "color": "#edebe8",
                    "scale": 1.31,
                    "zoom": 14
                },
                {
                    "color": "#edebe8",
                    "scale": 1.19,
                    "zoom": 15
                },
                {
                    "color": "#efedea",
                    "scale": 1.31,
                    "zoom": 16
                },
                {
                    "color": "#f0eeec",
                    "scale": 1.21,
                    "zoom": 17
                },
                {
                    "color": "#f2f0ee",
                    "scale": 1.17,
                    "zoom": 18
                },
                {
                    "color": "#f4f2f0",
                    "scale": 1.18,
                    "zoom": 19
                },
                {
                    "color": "#f5f3f1",
                    "scale": 1.23,
                    "zoom": 20
                },
                {
                    "color": "#f6f5f3",
                    "scale": 1.33,
                    "zoom": 21
                }
            ]
        },
        {
            "tags": {
                "any": "road_minor",
                "none": "is_tunnel"
            },
            "elements": "geometry.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "scale": 0,
                    "zoom": 0
                },
                {
                    "scale": 0,
                    "zoom": 1
                },
                {
                    "scale": 0,
                    "zoom": 2
                },
                {
                    "scale": 0,
                    "zoom": 3
                },
                {
                    "scale": 0,
                    "zoom": 4
                },
                {
                    "scale": 0,
                    "zoom": 5
                },
                {
                    "scale": 0,
                    "zoom": 6
                },
                {
                    "scale": 0,
                    "zoom": 7
                },
                {
                    "scale": 0,
                    "zoom": 8
                },
                {
                    "scale": 0,
                    "zoom": 9
                },
                {
                    "scale": 0,
                    "zoom": 10
                },
                {
                    "scale": 0,
                    "zoom": 11
                },
                {
                    "scale": 0,
                    "zoom": 12
                },
                {
                    "scale": 0,
                    "zoom": 13
                },
                {
                    "scale": 0,
                    "zoom": 14
                },
                {
                    "scale": 0,
                    "zoom": 15
                },
                {
                    "scale": 0.9,
                    "zoom": 16
                },
                {
                    "scale": 0.9,
                    "zoom": 17
                },
                {
                    "scale": 0.9,
                    "zoom": 18
                },
                {
                    "scale": 0.9,
                    "zoom": 19
                },
                {
                    "scale": 0.9,
                    "zoom": 20
                },
                {
                    "scale": 0.9,
                    "zoom": 21
                }
            ]
        },
        {
            "tags": {
                "any": "road_minor"
            },
            "elements": "geometry.outline",
            "stylers": [
                {
                    "color": "#ffffff",
                    "scale": 0.4,
                    "zoom": 0
                },
                {
                    "color": "#ffffff",
                    "scale": 0.4,
                    "zoom": 1
                },
                {
                    "color": "#ffffff",
                    "scale": 0.4,
                    "zoom": 2
                },
                {
                    "color": "#ffffff",
                    "scale": 0.4,
                    "zoom": 3
                },
                {
                    "color": "#ffffff",
                    "scale": 0.4,
                    "zoom": 4
                },
                {
                    "color": "#ffffff",
                    "scale": 0.4,
                    "zoom": 5
                },
                {
                    "color": "#ffffff",
                    "scale": 0.4,
                    "zoom": 6
                },
                {
                    "color": "#ffffff",
                    "scale": 0.4,
                    "zoom": 7
                },
                {
                    "color": "#ffffff",
                    "scale": 0.4,
                    "zoom": 8
                },
                {
                    "color": "#ffffff",
                    "scale": 0.4,
                    "zoom": 9
                },
                {
                    "color": "#ffffff",
                    "scale": 0.4,
                    "zoom": 10
                },
                {
                    "color": "#ffffff",
                    "scale": 0.4,
                    "zoom": 11
                },
                {
                    "color": "#ffffff",
                    "scale": 0.4,
                    "zoom": 12
                },
                {
                    "color": "#ffffff",
                    "scale": 0.4,
                    "zoom": 13
                },
                {
                    "color": "#edebe8",
                    "scale": 0.4,
                    "zoom": 14
                },
                {
                    "color": "#edebe8",
                    "scale": 0.4,
                    "zoom": 15
                },
                {
                    "color": "#efedea",
                    "scale": 1.4,
                    "zoom": 16
                },
                {
                    "color": "#f0eeec",
                    "scale": 1.27,
                    "zoom": 17
                },
                {
                    "color": "#f2f0ee",
                    "scale": 1.27,
                    "zoom": 18
                },
                {
                    "color": "#f4f2f0",
                    "scale": 1.29,
                    "zoom": 19
                },
                {
                    "color": "#f5f3f1",
                    "scale": 1.31,
                    "zoom": 20
                },
                {
                    "color": "#f6f5f3",
                    "scale": 1.32,
                    "zoom": 21
                }
            ]
        },
        {
            "tags": {
                "any": "road_unclassified",
                "none": "is_tunnel"
            },
            "elements": "geometry.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "scale": 0,
                    "zoom": 0
                },
                {
                    "scale": 0,
                    "zoom": 1
                },
                {
                    "scale": 0,
                    "zoom": 2
                },
                {
                    "scale": 0,
                    "zoom": 3
                },
                {
                    "scale": 0,
                    "zoom": 4
                },
                {
                    "scale": 0,
                    "zoom": 5
                },
                {
                    "scale": 0,
                    "zoom": 6
                },
                {
                    "scale": 0,
                    "zoom": 7
                },
                {
                    "scale": 0,
                    "zoom": 8
                },
                {
                    "scale": 0,
                    "zoom": 9
                },
                {
                    "scale": 0,
                    "zoom": 10
                },
                {
                    "scale": 0,
                    "zoom": 11
                },
                {
                    "scale": 0,
                    "zoom": 12
                },
                {
                    "scale": 0,
                    "zoom": 13
                },
                {
                    "scale": 0,
                    "zoom": 14
                },
                {
                    "scale": 0,
                    "zoom": 15
                },
                {
                    "scale": 0.9,
                    "zoom": 16
                },
                {
                    "scale": 0.9,
                    "zoom": 17
                },
                {
                    "scale": 0.9,
                    "zoom": 18
                },
                {
                    "scale": 0.9,
                    "zoom": 19
                },
                {
                    "scale": 0.9,
                    "zoom": 20
                },
                {
                    "scale": 0.9,
                    "zoom": 21
                }
            ]
        },
        {
            "tags": {
                "any": "road_unclassified"
            },
            "elements": "geometry.outline",
            "stylers": [
                {
                    "color": "#ffffff",
                    "scale": 0.4,
                    "zoom": 0
                },
                {
                    "color": "#ffffff",
                    "scale": 0.4,
                    "zoom": 1
                },
                {
                    "color": "#ffffff",
                    "scale": 0.4,
                    "zoom": 2
                },
                {
                    "color": "#ffffff",
                    "scale": 0.4,
                    "zoom": 3
                },
                {
                    "color": "#ffffff",
                    "scale": 0.4,
                    "zoom": 4
                },
                {
                    "color": "#ffffff",
                    "scale": 0.4,
                    "zoom": 5
                },
                {
                    "color": "#ffffff",
                    "scale": 0.4,
                    "zoom": 6
                },
                {
                    "color": "#ffffff",
                    "scale": 0.4,
                    "zoom": 7
                },
                {
                    "color": "#ffffff",
                    "scale": 0.4,
                    "zoom": 8
                },
                {
                    "color": "#ffffff",
                    "scale": 0.4,
                    "zoom": 9
                },
                {
                    "color": "#ffffff",
                    "scale": 0.4,
                    "zoom": 10
                },
                {
                    "color": "#ffffff",
                    "scale": 0.4,
                    "zoom": 11
                },
                {
                    "color": "#ffffff",
                    "scale": 0.4,
                    "zoom": 12
                },
                {
                    "color": "#ffffff",
                    "scale": 0.4,
                    "zoom": 13
                },
                {
                    "color": "#edebe8",
                    "scale": 0.4,
                    "zoom": 14
                },
                {
                    "color": "#edebe8",
                    "scale": 0.4,
                    "zoom": 15
                },
                {
                    "color": "#efedea",
                    "scale": 1.4,
                    "zoom": 16
                },
                {
                    "color": "#f0eeec",
                    "scale": 1.27,
                    "zoom": 17
                },
                {
                    "color": "#f2f0ee",
                    "scale": 1.27,
                    "zoom": 18
                },
                {
                    "color": "#f4f2f0",
                    "scale": 1.29,
                    "zoom": 19
                },
                {
                    "color": "#f5f3f1",
                    "scale": 1.31,
                    "zoom": 20
                },
                {
                    "color": "#f6f5f3",
                    "scale": 1.32,
                    "zoom": 21
                }
            ]
        },
        {
            "tags": {
                "all": "is_tunnel",
                "none": "path"
            },
            "elements": "geometry.fill",
            "stylers": [
                {
                    "color": "#e5e1dc",
                    "zoom": 0
                },
                {
                    "color": "#e5e1dc",
                    "zoom": 1
                },
                {
                    "color": "#e5e1dc",
                    "zoom": 2
                },
                {
                    "color": "#e5e1dc",
                    "zoom": 3
                },
                {
                    "color": "#e5e1dc",
                    "zoom": 4
                },
                {
                    "color": "#e5e1dc",
                    "zoom": 5
                },
                {
                    "color": "#e5e1dc",
                    "zoom": 6
                },
                {
                    "color": "#e5e1dc",
                    "zoom": 7
                },
                {
                    "color": "#e5e1dc",
                    "zoom": 8
                },
                {
                    "color": "#e5e1dc",
                    "zoom": 9
                },
                {
                    "color": "#e5e1dc",
                    "zoom": 10
                },
                {
                    "color": "#e5e1dc",
                    "zoom": 11
                },
                {
                    "color": "#e5e1dc",
                    "zoom": 12
                },
                {
                    "color": "#e5e1dc",
                    "zoom": 13
                },
                {
                    "color": "#e9e6e2",
                    "zoom": 14
                },
                {
                    "color": "#edebe8",
                    "zoom": 15
                },
                {
                    "color": "#eeece9",
                    "zoom": 16
                },
                {
                    "color": "#efedea",
                    "zoom": 17
                },
                {
                    "color": "#efedeb",
                    "zoom": 18
                },
                {
                    "color": "#f0eeec",
                    "zoom": 19
                },
                {
                    "color": "#f1efed",
                    "zoom": 20
                },
                {
                    "color": "#f2f0ee",
                    "zoom": 21
                }
            ]
        },
        {
            "tags": {
                "all": "path",
                "none": "is_tunnel"
            },
            "elements": "geometry.fill",
            "stylers": [
                {
                    "color": "#b2a699"
                }
            ]
        },
        {
            "tags": {
                "all": "path",
                "none": "is_tunnel"
            },
            "elements": "geometry.outline",
            "stylers": [
                {
                    "opacity": 0.7
                },
                {
                    "color": "#e9e6e2",
                    "zoom": 0
                },
                {
                    "color": "#e9e6e2",
                    "zoom": 1
                },
                {
                    "color": "#e9e6e2",
                    "zoom": 2
                },
                {
                    "color": "#e9e6e2",
                    "zoom": 3
                },
                {
                    "color": "#e9e6e2",
                    "zoom": 4
                },
                {
                    "color": "#e9e6e2",
                    "zoom": 5
                },
                {
                    "color": "#e9e6e2",
                    "zoom": 6
                },
                {
                    "color": "#e9e6e2",
                    "zoom": 7
                },
                {
                    "color": "#e9e6e2",
                    "zoom": 8
                },
                {
                    "color": "#e9e6e2",
                    "zoom": 9
                },
                {
                    "color": "#e9e6e2",
                    "zoom": 10
                },
                {
                    "color": "#e9e6e2",
                    "zoom": 11
                },
                {
                    "color": "#e9e6e2",
                    "zoom": 12
                },
                {
                    "color": "#e9e6e2",
                    "zoom": 13
                },
                {
                    "color": "#edebe8",
                    "zoom": 14
                },
                {
                    "color": "#f2f0ee",
                    "zoom": 15
                },
                {
                    "color": "#f3f1ef",
                    "zoom": 16
                },
                {
                    "color": "#f3f2f0",
                    "zoom": 17
                },
                {
                    "color": "#f4f2f0",
                    "zoom": 18
                },
                {
                    "color": "#f5f3f1",
                    "zoom": 19
                },
                {
                    "color": "#f5f4f2",
                    "zoom": 20
                },
                {
                    "color": "#f6f5f3",
                    "zoom": 21
                }
            ]
        },
        {
            "tags": "road_construction",
            "elements": "geometry.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "tags": "road_construction",
            "elements": "geometry.outline",
            "stylers": [
                {
                    "color": "#c8c0b6",
                    "zoom": 0
                },
                {
                    "color": "#c8c0b6",
                    "zoom": 1
                },
                {
                    "color": "#c8c0b6",
                    "zoom": 2
                },
                {
                    "color": "#c8c0b6",
                    "zoom": 3
                },
                {
                    "color": "#c8c0b6",
                    "zoom": 4
                },
                {
                    "color": "#c8c0b6",
                    "zoom": 5
                },
                {
                    "color": "#c8c0b6",
                    "zoom": 6
                },
                {
                    "color": "#c8c0b6",
                    "zoom": 7
                },
                {
                    "color": "#c8c0b6",
                    "zoom": 8
                },
                {
                    "color": "#c8c0b6",
                    "zoom": 9
                },
                {
                    "color": "#c8c0b6",
                    "zoom": 10
                },
                {
                    "color": "#c8c0b6",
                    "zoom": 11
                },
                {
                    "color": "#c8c0b6",
                    "zoom": 12
                },
                {
                    "color": "#c8c0b6",
                    "zoom": 13
                },
                {
                    "color": "#b2a699",
                    "zoom": 14
                },
                {
                    "color": "#c8c0b6",
                    "zoom": 15
                },
                {
                    "color": "#ccc4bb",
                    "zoom": 16
                },
                {
                    "color": "#cfc8c0",
                    "zoom": 17
                },
                {
                    "color": "#d3ccc4",
                    "zoom": 18
                },
                {
                    "color": "#d7d1c9",
                    "zoom": 19
                },
                {
                    "color": "#dad5ce",
                    "zoom": 20
                },
                {
                    "color": "#ded9d3",
                    "zoom": 21
                }
            ]
        },
        {
            "tags": {
                "any": "ferry"
            },
            "stylers": [
                {
                    "color": "#a59688"
                }
            ]
        },
        {
            "tags": "transit_location",
            "elements": "label.icon",
            "stylers": [
                {
                    "hue": "#e7e3df"
                },
                {
                    "saturation": -0.86
                }
            ]
        },
        {
            "tags": "transit_location",
            "elements": "label.text.fill",
            "stylers": [
                {
                    "color": "#b8997a"
                }
            ]
        },
        {
            "tags": "transit_location",
            "elements": "label.text.outline",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "tags": "transit_schema",
            "elements": "geometry.fill",
            "stylers": [
                {
                    "color": "#b8997a"
                },
                {
                    "scale": 0.7
                },
                {
                    "opacity": 0.6,
                    "zoom": 0
                },
                {
                    "opacity": 0.6,
                    "zoom": 1
                },
                {
                    "opacity": 0.6,
                    "zoom": 2
                },
                {
                    "opacity": 0.6,
                    "zoom": 3
                },
                {
                    "opacity": 0.6,
                    "zoom": 4
                },
                {
                    "opacity": 0.6,
                    "zoom": 5
                },
                {
                    "opacity": 0.6,
                    "zoom": 6
                },
                {
                    "opacity": 0.6,
                    "zoom": 7
                },
                {
                    "opacity": 0.6,
                    "zoom": 8
                },
                {
                    "opacity": 0.6,
                    "zoom": 9
                },
                {
                    "opacity": 0.6,
                    "zoom": 10
                },
                {
                    "opacity": 0.6,
                    "zoom": 11
                },
                {
                    "opacity": 0.6,
                    "zoom": 12
                },
                {
                    "opacity": 0.6,
                    "zoom": 13
                },
                {
                    "opacity": 0.6,
                    "zoom": 14
                },
                {
                    "opacity": 0.5,
                    "zoom": 15
                },
                {
                    "opacity": 0.4,
                    "zoom": 16
                },
                {
                    "opacity": 0.4,
                    "zoom": 17
                },
                {
                    "opacity": 0.4,
                    "zoom": 18
                },
                {
                    "opacity": 0.4,
                    "zoom": 19
                },
                {
                    "opacity": 0.4,
                    "zoom": 20
                },
                {
                    "opacity": 0.4,
                    "zoom": 21
                }
            ]
        },
        {
            "tags": "transit_schema",
            "elements": "geometry.outline",
            "stylers": [
                {
                    "opacity": 0
                }
            ]
        },
        {
            "tags": "transit_line",
            "elements": "geometry.fill.pattern",
            "stylers": [
                {
                    "color": "#c2b3a3"
                },
                {
                    "opacity": 0,
                    "zoom": 0
                },
                {
                    "opacity": 0,
                    "zoom": 1
                },
                {
                    "opacity": 0,
                    "zoom": 2
                },
                {
                    "opacity": 0,
                    "zoom": 3
                },
                {
                    "opacity": 0,
                    "zoom": 4
                },
                {
                    "opacity": 0,
                    "zoom": 5
                },
                {
                    "opacity": 0,
                    "zoom": 6
                },
                {
                    "opacity": 0,
                    "zoom": 7
                },
                {
                    "opacity": 0,
                    "zoom": 8
                },
                {
                    "opacity": 0,
                    "zoom": 9
                },
                {
                    "opacity": 0,
                    "zoom": 10
                },
                {
                    "opacity": 0,
                    "zoom": 11
                },
                {
                    "opacity": 0,
                    "zoom": 12
                },
                {
                    "opacity": 1,
                    "zoom": 13
                },
                {
                    "opacity": 1,
                    "zoom": 14
                },
                {
                    "opacity": 1,
                    "zoom": 15
                },
                {
                    "opacity": 1,
                    "zoom": 16
                },
                {
                    "opacity": 1,
                    "zoom": 17
                },
                {
                    "opacity": 1,
                    "zoom": 18
                },
                {
                    "opacity": 1,
                    "zoom": 19
                },
                {
                    "opacity": 1,
                    "zoom": 20
                },
                {
                    "opacity": 1,
                    "zoom": 21
                }
            ]
        },
        {
            "tags": "transit_line",
            "elements": "geometry.fill",
            "stylers": [
                {
                    "color": "#c2b3a3"
                },
                {
                    "scale": 0.4
                },
                {
                    "opacity": 0,
                    "zoom": 0
                },
                {
                    "opacity": 0,
                    "zoom": 1
                },
                {
                    "opacity": 0,
                    "zoom": 2
                },
                {
                    "opacity": 0,
                    "zoom": 3
                },
                {
                    "opacity": 0,
                    "zoom": 4
                },
                {
                    "opacity": 0,
                    "zoom": 5
                },
                {
                    "opacity": 0,
                    "zoom": 6
                },
                {
                    "opacity": 0,
                    "zoom": 7
                },
                {
                    "opacity": 0,
                    "zoom": 8
                },
                {
                    "opacity": 0,
                    "zoom": 9
                },
                {
                    "opacity": 0,
                    "zoom": 10
                },
                {
                    "opacity": 0,
                    "zoom": 11
                },
                {
                    "opacity": 0,
                    "zoom": 12
                },
                {
                    "opacity": 1,
                    "zoom": 13
                },
                {
                    "opacity": 1,
                    "zoom": 14
                },
                {
                    "opacity": 1,
                    "zoom": 15
                },
                {
                    "opacity": 1,
                    "zoom": 16
                },
                {
                    "opacity": 1,
                    "zoom": 17
                },
                {
                    "opacity": 1,
                    "zoom": 18
                },
                {
                    "opacity": 1,
                    "zoom": 19
                },
                {
                    "opacity": 1,
                    "zoom": 20
                },
                {
                    "opacity": 1,
                    "zoom": 21
                }
            ]
        },
        {
            "tags": "water",
            "elements": "geometry",
            "stylers": [
                {
                    "color": "#bbb0a5",
                    "zoom": 0
                },
                {
                    "color": "#bbb0a5",
                    "zoom": 1
                },
                {
                    "color": "#bbb0a5",
                    "zoom": 2
                },
                {
                    "color": "#bbb0a5",
                    "zoom": 3
                },
                {
                    "color": "#bbb0a5",
                    "zoom": 4
                },
                {
                    "color": "#bbb0a5",
                    "zoom": 5
                },
                {
                    "color": "#bbb0a5",
                    "zoom": 6
                },
                {
                    "color": "#bbb0a5",
                    "zoom": 7
                },
                {
                    "color": "#bcb2a7",
                    "zoom": 8
                },
                {
                    "color": "#beb3a9",
                    "zoom": 9
                },
                {
                    "color": "#bfb5ab",
                    "zoom": 10
                },
                {
                    "color": "#c0b6ac",
                    "zoom": 11
                },
                {
                    "color": "#c1b7ad",
                    "zoom": 12
                },
                {
                    "color": "#c2b8ae",
                    "zoom": 13
                },
                {
                    "color": "#c3b9af",
                    "zoom": 14
                },
                {
                    "color": "#c4bab1",
                    "zoom": 15
                },
                {
                    "color": "#c5bcb2",
                    "zoom": 16
                },
                {
                    "color": "#c6bdb3",
                    "zoom": 17
                },
                {
                    "color": "#c7beb5",
                    "zoom": 18
                },
                {
                    "color": "#c8bfb6",
                    "zoom": 19
                },
                {
                    "color": "#c9c1b8",
                    "zoom": 20
                },
                {
                    "color": "#cac2b9",
                    "zoom": 21
                }
            ]
        },
        {
            "tags": "water",
            "elements": "geometry",
            "types": "polyline",
            "stylers": [
                {
                    "opacity": 0.4,
                    "zoom": 0
                },
                {
                    "opacity": 0.4,
                    "zoom": 1
                },
                {
                    "opacity": 0.4,
                    "zoom": 2
                },
                {
                    "opacity": 0.4,
                    "zoom": 3
                },
                {
                    "opacity": 0.6,
                    "zoom": 4
                },
                {
                    "opacity": 0.8,
                    "zoom": 5
                },
                {
                    "opacity": 1,
                    "zoom": 6
                },
                {
                    "opacity": 1,
                    "zoom": 7
                },
                {
                    "opacity": 1,
                    "zoom": 8
                },
                {
                    "opacity": 1,
                    "zoom": 9
                },
                {
                    "opacity": 1,
                    "zoom": 10
                },
                {
                    "opacity": 1,
                    "zoom": 11
                },
                {
                    "opacity": 1,
                    "zoom": 12
                },
                {
                    "opacity": 1,
                    "zoom": 13
                },
                {
                    "opacity": 1,
                    "zoom": 14
                },
                {
                    "opacity": 1,
                    "zoom": 15
                },
                {
                    "opacity": 1,
                    "zoom": 16
                },
                {
                    "opacity": 1,
                    "zoom": 17
                },
                {
                    "opacity": 1,
                    "zoom": 18
                },
                {
                    "opacity": 1,
                    "zoom": 19
                },
                {
                    "opacity": 1,
                    "zoom": 20
                },
                {
                    "opacity": 1,
                    "zoom": 21
                }
            ]
        },
        {
            "tags": "bathymetry",
            "elements": "geometry",
            "stylers": [
                {
                    "hue": "#bbb0a5"
                }
            ]
        },
        {
            "tags": {
                "any": [
                    "industrial",
                    "construction_site"
                ]
            },
            "elements": "geometry",
            "stylers": [
                {
                    "color": "#e5e0dc",
                    "zoom": 0
                },
                {
                    "color": "#e5e0dc",
                    "zoom": 1
                },
                {
                    "color": "#e5e0dc",
                    "zoom": 2
                },
                {
                    "color": "#e5e0dc",
                    "zoom": 3
                },
                {
                    "color": "#e5e0dc",
                    "zoom": 4
                },
                {
                    "color": "#e5e0dc",
                    "zoom": 5
                },
                {
                    "color": "#e5e0dc",
                    "zoom": 6
                },
                {
                    "color": "#e5e0dc",
                    "zoom": 7
                },
                {
                    "color": "#e5e0dc",
                    "zoom": 8
                },
                {
                    "color": "#e5e0dc",
                    "zoom": 9
                },
                {
                    "color": "#e5e0dc",
                    "zoom": 10
                },
                {
                    "color": "#e5e0dc",
                    "zoom": 11
                },
                {
                    "color": "#e5e0dc",
                    "zoom": 12
                },
                {
                    "color": "#e5e0dc",
                    "zoom": 13
                },
                {
                    "color": "#e9e5e2",
                    "zoom": 14
                },
                {
                    "color": "#eeeae8",
                    "zoom": 15
                },
                {
                    "color": "#efebe9",
                    "zoom": 16
                },
                {
                    "color": "#efecea",
                    "zoom": 17
                },
                {
                    "color": "#f0ecea",
                    "zoom": 18
                },
                {
                    "color": "#f1edeb",
                    "zoom": 19
                },
                {
                    "color": "#f1eeec",
                    "zoom": 20
                },
                {
                    "color": "#f2efed",
                    "zoom": 21
                }
            ]
        },
        {
            "tags": {
                "any": "transit",
                "none": [
                    "transit_location",
                    "transit_line",
                    "transit_schema",
                    "is_unclassified_transit"
                ]
            },
            "elements": "geometry",
            "stylers": [
                {
                    "color": "#e5e0dc",
                    "zoom": 0
                },
                {
                    "color": "#e5e0dc",
                    "zoom": 1
                },
                {
                    "color": "#e5e0dc",
                    "zoom": 2
                },
                {
                    "color": "#e5e0dc",
                    "zoom": 3
                },
                {
                    "color": "#e5e0dc",
                    "zoom": 4
                },
                {
                    "color": "#e5e0dc",
                    "zoom": 5
                },
                {
                    "color": "#e5e0dc",
                    "zoom": 6
                },
                {
                    "color": "#e5e0dc",
                    "zoom": 7
                },
                {
                    "color": "#e5e0dc",
                    "zoom": 8
                },
                {
                    "color": "#e5e0dc",
                    "zoom": 9
                },
                {
                    "color": "#e5e0dc",
                    "zoom": 10
                },
                {
                    "color": "#e5e0dc",
                    "zoom": 11
                },
                {
                    "color": "#e5e0dc",
                    "zoom": 12
                },
                {
                    "color": "#e5e0dc",
                    "zoom": 13
                },
                {
                    "color": "#e9e5e2",
                    "zoom": 14
                },
                {
                    "color": "#eeeae8",
                    "zoom": 15
                },
                {
                    "color": "#efebe9",
                    "zoom": 16
                },
                {
                    "color": "#efecea",
                    "zoom": 17
                },
                {
                    "color": "#f0ecea",
                    "zoom": 18
                },
                {
                    "color": "#f1edeb",
                    "zoom": 19
                },
                {
                    "color": "#f1eeec",
                    "zoom": 20
                },
                {
                    "color": "#f2efed",
                    "zoom": 21
                }
            ]
        },
        {
            "tags": "fence",
            "elements": "geometry.fill",
            "stylers": [
                {
                    "color": "#dcd6d0"
                },
                {
                    "opacity": 0.75,
                    "zoom": 0
                },
                {
                    "opacity": 0.75,
                    "zoom": 1
                },
                {
                    "opacity": 0.75,
                    "zoom": 2
                },
                {
                    "opacity": 0.75,
                    "zoom": 3
                },
                {
                    "opacity": 0.75,
                    "zoom": 4
                },
                {
                    "opacity": 0.75,
                    "zoom": 5
                },
                {
                    "opacity": 0.75,
                    "zoom": 6
                },
                {
                    "opacity": 0.75,
                    "zoom": 7
                },
                {
                    "opacity": 0.75,
                    "zoom": 8
                },
                {
                    "opacity": 0.75,
                    "zoom": 9
                },
                {
                    "opacity": 0.75,
                    "zoom": 10
                },
                {
                    "opacity": 0.75,
                    "zoom": 11
                },
                {
                    "opacity": 0.75,
                    "zoom": 12
                },
                {
                    "opacity": 0.75,
                    "zoom": 13
                },
                {
                    "opacity": 0.75,
                    "zoom": 14
                },
                {
                    "opacity": 0.75,
                    "zoom": 15
                },
                {
                    "opacity": 0.75,
                    "zoom": 16
                },
                {
                    "opacity": 0.45,
                    "zoom": 17
                },
                {
                    "opacity": 0.45,
                    "zoom": 18
                },
                {
                    "opacity": 0.45,
                    "zoom": 19
                },
                {
                    "opacity": 0.45,
                    "zoom": 20
                },
                {
                    "opacity": 0.45,
                    "zoom": 21
                }
            ]
        },
        {
            "tags": "medical",
            "elements": "geometry",
            "stylers": [
                {
                    "color": "#e5e0dc",
                    "zoom": 0
                },
                {
                    "color": "#e5e0dc",
                    "zoom": 1
                },
                {
                    "color": "#e5e0dc",
                    "zoom": 2
                },
                {
                    "color": "#e5e0dc",
                    "zoom": 3
                },
                {
                    "color": "#e5e0dc",
                    "zoom": 4
                },
                {
                    "color": "#e5e0dc",
                    "zoom": 5
                },
                {
                    "color": "#e5e0dc",
                    "zoom": 6
                },
                {
                    "color": "#e5e0dc",
                    "zoom": 7
                },
                {
                    "color": "#e5e0dc",
                    "zoom": 8
                },
                {
                    "color": "#e5e0dc",
                    "zoom": 9
                },
                {
                    "color": "#e5e0dc",
                    "zoom": 10
                },
                {
                    "color": "#e5e0dc",
                    "zoom": 11
                },
                {
                    "color": "#e5e0dc",
                    "zoom": 12
                },
                {
                    "color": "#e5e0dc",
                    "zoom": 13
                },
                {
                    "color": "#e9e5e2",
                    "zoom": 14
                },
                {
                    "color": "#eeeae8",
                    "zoom": 15
                },
                {
                    "color": "#efebe9",
                    "zoom": 16
                },
                {
                    "color": "#efecea",
                    "zoom": 17
                },
                {
                    "color": "#f0ecea",
                    "zoom": 18
                },
                {
                    "color": "#f1edeb",
                    "zoom": 19
                },
                {
                    "color": "#f1eeec",
                    "zoom": 20
                },
                {
                    "color": "#f2efed",
                    "zoom": 21
                }
            ]
        },
        {
            "tags": "beach",
            "elements": "geometry",
            "stylers": [
                {
                    "color": "#e5e0dc",
                    "opacity": 0.3,
                    "zoom": 0
                },
                {
                    "color": "#e5e0dc",
                    "opacity": 0.3,
                    "zoom": 1
                },
                {
                    "color": "#e5e0dc",
                    "opacity": 0.3,
                    "zoom": 2
                },
                {
                    "color": "#e5e0dc",
                    "opacity": 0.3,
                    "zoom": 3
                },
                {
                    "color": "#e5e0dc",
                    "opacity": 0.3,
                    "zoom": 4
                },
                {
                    "color": "#e5e0dc",
                    "opacity": 0.3,
                    "zoom": 5
                },
                {
                    "color": "#e5e0dc",
                    "opacity": 0.3,
                    "zoom": 6
                },
                {
                    "color": "#e5e0dc",
                    "opacity": 0.3,
                    "zoom": 7
                },
                {
                    "color": "#e5e0dc",
                    "opacity": 0.3,
                    "zoom": 8
                },
                {
                    "color": "#e5e0dc",
                    "opacity": 0.3,
                    "zoom": 9
                },
                {
                    "color": "#e5e0dc",
                    "opacity": 0.3,
                    "zoom": 10
                },
                {
                    "color": "#e5e0dc",
                    "opacity": 0.3,
                    "zoom": 11
                },
                {
                    "color": "#e5e0dc",
                    "opacity": 0.3,
                    "zoom": 12
                },
                {
                    "color": "#e5e0dc",
                    "opacity": 0.65,
                    "zoom": 13
                },
                {
                    "color": "#e9e5e2",
                    "opacity": 1,
                    "zoom": 14
                },
                {
                    "color": "#eeeae8",
                    "opacity": 1,
                    "zoom": 15
                },
                {
                    "color": "#efebe9",
                    "opacity": 1,
                    "zoom": 16
                },
                {
                    "color": "#efecea",
                    "opacity": 1,
                    "zoom": 17
                },
                {
                    "color": "#f0ecea",
                    "opacity": 1,
                    "zoom": 18
                },
                {
                    "color": "#f1edeb",
                    "opacity": 1,
                    "zoom": 19
                },
                {
                    "color": "#f1eeec",
                    "opacity": 1,
                    "zoom": 20
                },
                {
                    "color": "#f2efed",
                    "opacity": 1,
                    "zoom": 21
                }
            ]
        },
        {
            "tags": {
                "all": [
                    "is_tunnel",
                    "path"
                ]
            },
            "elements": "geometry.fill",
            "stylers": [
                {
                    "color": "#aea193"
                },
                {
                    "opacity": 0.3
                }
            ]
        },
        {
            "tags": {
                "all": [
                    "is_tunnel",
                    "path"
                ]
            },
            "elements": "geometry.outline",
            "stylers": [
                {
                    "opacity": 0
                }
            ]
        },
        {
            "tags": "road_limited",
            "elements": "geometry.fill",
            "stylers": [
                {
                    "color": "#bdb3a8"
                },
                {
                    "scale": 0,
                    "zoom": 0
                },
                {
                    "scale": 0,
                    "zoom": 1
                },
                {
                    "scale": 0,
                    "zoom": 2
                },
                {
                    "scale": 0,
                    "zoom": 3
                },
                {
                    "scale": 0,
                    "zoom": 4
                },
                {
                    "scale": 0,
                    "zoom": 5
                },
                {
                    "scale": 0,
                    "zoom": 6
                },
                {
                    "scale": 0,
                    "zoom": 7
                },
                {
                    "scale": 0,
                    "zoom": 8
                },
                {
                    "scale": 0,
                    "zoom": 9
                },
                {
                    "scale": 0,
                    "zoom": 10
                },
                {
                    "scale": 0,
                    "zoom": 11
                },
                {
                    "scale": 0,
                    "zoom": 12
                },
                {
                    "scale": 0.1,
                    "zoom": 13
                },
                {
                    "scale": 0.2,
                    "zoom": 14
                },
                {
                    "scale": 0.3,
                    "zoom": 15
                },
                {
                    "scale": 0.5,
                    "zoom": 16
                },
                {
                    "scale": 0.6,
                    "zoom": 17
                },
                {
                    "scale": 0.7,
                    "zoom": 18
                },
                {
                    "scale": 0.79,
                    "zoom": 19
                },
                {
                    "scale": 0.83,
                    "zoom": 20
                },
                {
                    "scale": 0.9,
                    "zoom": 21
                }
            ]
        },
        {
            "tags": "road_limited",
            "elements": "geometry.outline",
            "stylers": [
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 0
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 1
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 2
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 3
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 4
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 5
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 6
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 7
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 8
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 9
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 10
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 11
                },
                {
                    "color": "#ffffff",
                    "scale": 1.4,
                    "zoom": 12
                },
                {
                    "color": "#ffffff",
                    "scale": 0.1,
                    "zoom": 13
                },
                {
                    "color": "#edebe8",
                    "scale": 0.2,
                    "zoom": 14
                },
                {
                    "color": "#edebe8",
                    "scale": 0.3,
                    "zoom": 15
                },
                {
                    "color": "#efedea",
                    "scale": 0.5,
                    "zoom": 16
                },
                {
                    "color": "#f0eeec",
                    "scale": 0.6,
                    "zoom": 17
                },
                {
                    "color": "#f2f0ee",
                    "scale": 0.7,
                    "zoom": 18
                },
                {
                    "color": "#f4f2f0",
                    "scale": 1.18,
                    "zoom": 19
                },
                {
                    "color": "#f5f3f1",
                    "scale": 1.23,
                    "zoom": 20
                },
                {
                    "color": "#f6f5f3",
                    "scale": 1.33,
                    "zoom": 21
                }
            ]
        },
        {
            "tags": {
                "any": [
                    "food_and_drink",
                    "shopping",
                    "commercial_services"
                ]
            },
            "stylers": {
                "visibility": "off"
            }
        },
        {
            "tags": {
                "any": [
                    "traffic_light"
                ]
            },
            "stylers": {
                "visibility": "off"
            }
        },
        {
            "tags": {
                "any": [
                    "entrance"
                ]
            },
            "stylers": {
                "visibility": "off"
            }
        },
        {
            "tags": {
                "any": [
                    "road"
                ],
                "none": [
                    "road_1",
                    "road_2",
                    "road_3",
                    "road_4",
                    "road_5",
                    "road_6",
                    "road_7"
                ]
            },
            "elements": "label.icon",
            "stylers": {
                "visibility": "off"
            }
        },
        {
            "tags": {
                "any": [
                    "building",
                    "address",
                    "fence"
                ]
            },
            "stylers": {
                "visibility": "off"
            }
        },
        {
            "tags": {
                "any": "landcover",
                "none": "vegetation"
            },
            "stylers": {
                "visibility": "off"
            }
        }
    ], {
        name: 'Custom Style'
    });
    let customMapTypeId = 'custom_style';

    document.querySelectorAll('[data-map]').forEach(function initMapElement(mapElement) {
        let map = new google.maps.Map(mapElement, {
            zoom: 14,
            center: {
                lat: parseFloat(mapElement.dataset.lat),
                lng: parseFloat(mapElement.dataset.lng)
            },
            mapTypeControlOptions: {
                mapTypeIds: [google.maps.MapTypeId.ROADMAP, customMapTypeId]
            },
            panControl: false,
            zoomControl: true,
            scaleControl: false,
            streetViewControl: false,
            scrollwheel: false,
            mapTypeControl: false
        });

        // map.mapTypes.set(customMapTypeId, customMapType);
        // map.setMapTypeId(customMapTypeId);

        let marker = new google.maps.Marker({
            position: {
                lat: parseFloat(mapElement.dataset.lat),
                lng: parseFloat(mapElement.dataset.lng)
            },
            icon: mapElement.dataset.mark,
            map: map
        });
    });
    /*
    marker.addListener('click', function() {
      infowindow.open(map, marker);
    });
    */
}

function initMapYandex() {
    document.querySelectorAll('[data-map]').forEach(function initMapElement(mapElement) {
        let myMap = new ymaps.Map(mapElement, {
            center: [parseFloat(mapElement.dataset.lat), parseFloat(mapElement.dataset.lng)],
            zoom: mapElement.dataset.zoom,
            controls: ['zoomControl', 'fullscreenControl']
        }, {
            searchControlProvider: 'yandex#search'
        });
        let myPlacemark = new ymaps.Placemark(myMap.getCenter(), {}, {
            // Опции.
            // Необходимо указать данный тип макета.
            iconLayout: 'default#image',
            // Своё изображение иконки метки.
            iconImageHref: mapElement.dataset.mark,
            // Размеры метки.
            iconImageSize: [66, 75],
            // Смещение левого верхнего угла иконки относительно
            // её "ножки" (точки привязки).
            iconImageOffset: [-21, -57]
        });


        myMap.behaviors.disable('scrollZoom');
        if (window.innerWidth <= 1024) {
            myMap.behaviors.disable('drag');
        }
        if (window.innerWidth <= 767) {
            let myPlacemark = new ymaps.Placemark(myMap.getCenter(), {}, {
                // Опции.
                // Необходимо указать данный тип макета.
                iconLayout: 'default#image',
                // Своё изображение иконки метки.
                iconImageHref: mapElement.dataset.mark,
                // Размеры метки.
                iconImageSize: [45, 51],
                // Смещение левого верхнего угла иконки относительно
                // её "ножки" (точки привязки).
                iconImageOffset: [-21, -57]
            });
            myMap.geoObjects.add(myPlacemark);
        }
        else {
            myMap.geoObjects.add(myPlacemark);
        }
    });
}
// google.maps.event.addDomListener(window, 'load', initMap);

window.addEventListener('load', () => {
    if (window.google) {
        initMapGoogle();
    }
    if (window.ymaps) {
        ymaps.ready(initMapYandex);
    }
});

