import {onDomReady} from "../../components/dynamic/observer";
import {EffectCreative, Navigation, Pagination, Swiper} from 'swiper';
// import '../../../node_modules/swiper/swiper.scss';
import '../../../node_modules/swiper/swiper-bundle.min.css';

class SliderImages {

    constructor(sliderBlock)
    {
        this.sliderBlock = sliderBlock;
        this.swipeSlider = sliderBlock.querySelector('[data-images-slider]');
        this.init();
        this.eventListeners();
        this.update();
    }

    eventListeners()
    {
        window.addEventListener('resize', () => {
            this.update();
        });
    }

    update()
    {
        this.slides = this.swipeSlider.querySelectorAll('[data-images-slide]');

        // If there are less than 7 slides and the viewport is larger than 767px, disable the slider
        if (this.slides.length < 7 && document.documentElement.clientWidth > 767) {
            this.removeSliderStyles();
            return;
        }

        // If there are less than 4 slides and the viewport is smaller than 767px, disable the slider
        if (this.slides.length < 4 && document.documentElement.clientWidth < 767) {
            this.removeSliderStyles();
            return;
        }

        // Start slider
        this.addSliderStyles();
        this.init();
    }

    addSliderStyles()
    {
        this.swipeSlider.classList.add('swiper');
        this.slides.forEach((slide) => {
            slide.classList.add('swiper-slide');
        });
        this.swipeSlider.querySelector('[data-images-slider-wrapper]').classList.add('swiper-wrapper');
        this.swipeSlider.querySelector('[data-images-slider-wrapper]').classList.remove('grid-block');
    }

    removeSliderStyles()
    {
        this.swipeSlider.classList.remove('swiper');
        this.slides.forEach((slide) => {
            slide.classList.remove('swiper-slide');
        });
        this.swipeSlider.querySelector('[data-images-slider-wrapper]').classList.remove('swiper-wrapper');
        this.swipeSlider.querySelector('[data-images-slider-wrapper]').classList.add('grid-block');
    }

    init()
    {
        const leftArrow = this.sliderBlock.querySelector('[data-images-slider-left]');
        const rightArrow = this.sliderBlock.querySelector('[data-images-slider-right]');

        const slider = new Swiper(this.swipeSlider, {
            modules: [Navigation, EffectCreative, Pagination],
            slidesPerView: "auto",
            speed: 500,
            grabCursor: true,
            preventInteractionOnTransition: true,
            navigation: {
                prevEl: leftArrow,
                nextEl: rightArrow,
            },
        });
    }
}

onDomReady(() => {
    // Это не типичный слайдер, он работает только когда слайдов много или мало места
    const sliderBlocks = document.querySelectorAll('[data-images-slider-block]');
    sliderBlocks.forEach((sliderBlock) =>{
        if (!sliderBlock) {
            return;
        }

        const slider = new SliderImages(sliderBlock);
    });
});
