import {createApp} from "vue";
import {map} from "./map";
import {FilterManagerPool} from "./manager";

export const initFilters = function (store) {
    let createdFieldSets = false;

    document.querySelectorAll('[data-filter-fieldset]').forEach((element) => {
        const filterKey = element.dataset.filterKey;
        if (!filterKey) {
            console.error('Fieldset`s data-filter-key is empty', element);
            return;
        }
        const fieldsetType = element.dataset.filterFieldsetType;
        if (!fieldsetType) {
            console.error('Fieldset`s data-filter-fieldset-type is empty', element);
            return;
        }

        const fieldset = element.dataset.filterFieldset;
        if (!fieldset) {
            console.error('Fieldset`s data-filter-fieldset is empty', element);
            return;
        }

        const fieldsetComponent = `${fieldsetType}Fieldset`;
        if (!map[fieldsetComponent]) {
            console.error(`Unknown fieldset component ${fieldsetComponent}`, element);
            return;
        }
        const app = createApp(map[fieldsetComponent], {
            filterKey,
            fieldsetKey: fieldset
        });
        for (const name in map) {
            app.component(name, map[name]);
        }
        app.use(store);
        app.mount(element);
        createdFieldSets = true;
    });

    // Инициализация filterManager и установка данных
    if (createdFieldSets) {
        document.querySelectorAll('[data-filter-metadata]').forEach((script) => {
            const filterKey = script.dataset.filterKey;
            if (!filterKey) {
                console.error('Script`s data-filter-key is empty', script);
                return;
            }

            const filterManager = FilterManagerPool.getManager(filterKey, script.dataset);
            filterManager.readDataFromDocument(document, true);
        });
    }

    document.dispatchEvent(new CustomEvent('filter:after-init'));
}

// Загружаем данные в стор, обходим филдсеты и кастуем аппки