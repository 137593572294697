import {onDomReady} from "../../components/dynamic/observer";
import {EffectFade, Swiper, Autoplay} from 'swiper';
import '../../../node_modules/swiper/swiper.scss';
import RSlider from "../../components/rslider/rslider";

function init() {
    const sliderContainers = document.querySelectorAll('[data-partners-slider-container]');

    if (!sliderContainers) {
        return;
    }

    sliderContainers.forEach((sliderContainer) => {
        const swipeSliderElement = sliderContainer.querySelector('[data-partners-slider]');
        if (!swipeSliderElement) {
            return;
        }

        const sliderPagination = sliderContainer.querySelectorAll('[data-partners-slider-pagination]');

        const slider = new Swiper(swipeSliderElement, {
            modules: [EffectFade, Autoplay],
            slidesPerView: "auto",
            preventInteractionOnTransition: true,
            loop: true,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
            },
            effect: "fade",
        });

        const textElement = sliderContainer.querySelector('[data-rslider="partners-text-slider"]');
        if (!textElement) {
            console.log('Could not find any element with data-index-text-slider');
            return;
        }
        const textSlider = new RSlider(
            textElement,
            {
                onStart: ({ slideIn, slideFriendsIn }) => {
                    slideFriendsIn.forEach((friendIn) => {
                        friendIn.classList.add('_active');
                    })
                    slideIn.classList.add('_active');
                },
                timeline: [
                    RSlider.action({
                        // К слайдам докидывается:
                        // К появляющемуся: --rslider-first-act-in-progress: 0.2;
                        // К исчезающему: --rslider-first-act-out-progress: 0.2;
                        // easing ниже настраивается
                        name: 'next-act',
                        duration: 1000,
                        delay: 0,
                        // easeInOutCubic прогресс для "приходящего" слайда
                        // easingIn: (x) => x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2,
                        // linear прогресс для "уходящего" слайда
                        // easingOut: (x) => x,
                        onStart: ({ slideIn, slideOut, slideFriendsIn, slideFriendsOut }) => {
                            slideIn.classList.add('_active');
                            slideOut.classList.remove('_active');

                            slideIn.classList.add('_in-next-act');
                            slideOut.classList.add('_out-next-act');

                            slideFriendsIn.forEach((friendIn) => {
                                friendIn.classList.add('_active');
                                friendIn.classList.add('_in-next-act');
                            })

                            setTimeout(() => {
                                slideIn.classList.remove('_in-next-act');

                                slideFriendsIn.forEach((friendIn) => {
                                    friendIn.classList.remove('_in-next-act');
                                })
                            }, 500);

                            slideFriendsOut.forEach((friendOut) => {
                                friendOut.classList.remove('_active');
                                friendOut.classList.add('_out-next-act');
                            })
                        },
                        onEnd: ({ slideIn, slideOut, slideFriendsIn, slideFriendsOut }) => {
                            slideOut.classList.remove('_out-next-act');

                            slideFriendsOut.forEach((friendOut) => {
                                friendOut.classList.remove('_out-next-act');
                            })
                        }
                    }),
                ],
            });

        slider.on('slideNextTransitionStart', () => {
            togglePagination({sliderPagination, index: slider.realIndex})
            textSlider.next();
        });

        slider.on('slidePrevTransitionStart', () => {
            togglePagination({sliderPagination, index: slider.realIndex})
            textSlider.prev();
        });

        if (sliderPagination) {
            sliderPagination[0].classList.add('_active');
            sliderPagination.forEach((item) => {
                const paginationIndex = parseInt(item.dataset.partnersSliderPagination, 10)
                item.addEventListener('click', () => {
                    slider.slideTo(paginationIndex);
                })
            });
        }
    })
}

function togglePagination(data) {
    data.sliderPagination.forEach((item) => {
        if (item.classList.contains('_active')) {
            item.classList.remove('_active');
        }
    })

    data.sliderPagination[data.index].classList.add('_active');
}

onDomReady(init);