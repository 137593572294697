/**
 *  Использование в стилях:
 *  height: calc(var(--vh, 1vh) * 100);
 *
 *  Поймать событие:
 *  document.addEventListener("VhVarUpdate", ()=>{});
 *
 *  Android, Chrome - срабатывает при отпускания пальца от экрана
 *  IOS, Safari - срабатывает во время скролла
 *
 */
function setVhVar()
{
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    const vw = document.documentElement.clientWidth * 0.01;
    document.documentElement.style.setProperty('--vw', `${vw}px`);
    const event = new Event('VhVarUpdate');
    document.dispatchEvent(event);
}

document.addEventListener('DOMContentLoaded', setVhVar);
window.addEventListener('resize', setVhVar);