import { createPinia } from "pinia";
import { initFilters } from "./filter/init";
import {createApp} from "vue";
import TheFilterMobLink from "./components/filter/TheFilterMobLink.vue";

const pinia = createPinia();
initFilters(pinia);

if (document.querySelector('#filter-mob-link')) {
    const app = createApp(
        TheFilterMobLink
    ).use(pinia);
    app.mount('#filter-mob-link');
}