import {onDomReady} from "../../components/dynamic/observer";

class CityOpener {
    constructor(opener) {
        this.cityOpener = opener;
        this.closer = document.querySelector('[data-city-opener-closer]');
        this.init();
    }

    init() {
        this.addEvents();
    }

    addEvents() {
        this.cityOpener.addEventListener('click', (e) => {
            this.cityOpener.classList.add('_opened');
        });

        this.closer.addEventListener('click', () => {
            if (this.cityOpener.classList.contains('_opened')) {
                this.cityOpener.classList.remove('_opened');
            }
        })
    }
}

onDomReady(() => {
    if (window.innerWidth > 1179) {
        return;
    }

    const cityOpener = document.querySelector('[data-city-opener]');

    if (!cityOpener) {
        return;
    }

    new CityOpener(cityOpener);
});