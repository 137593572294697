import {onDomReady} from "../../components/dynamic/observer";
import {Navigation, Pagination, Parallax, Swiper} from 'swiper';
import RSlider from "../../components/rslider/rslider";

function init() {
    const containers = document.querySelectorAll('[data-partners-products-slider-container]');
    if (!containers) {
        return;
    }

    containers.forEach((container) => {
        const element = container.querySelector('[data-partners-products-slider]');
        if (!element) {
            return;
        }

        const prevElement = container.querySelector('[data-partners-products-slider-prev]');
        const nextElement = container.querySelector('[data-partners-products-slider-next]');
        const paginationElement = container.querySelector('[data-partners-products-slider-pagination]');

        const slider = new Swiper(element, {
            modules: [Navigation, Pagination, Parallax],
            slidesPerView: 1,
            speed: 1000,
            parallax: true,
            loop: true,
            grabCursor: true,
            preventInteractionOnTransition: true,
            pagination:{
                el: paginationElement,
                type: 'fraction',
                renderFraction: function (currentClass, totalClass) {
                    return '<span class="' + currentClass + ' text__caption-middle"></span>' +
                        '<span class="swiper-pagination-line text__caption-middle">/</span>' +
                        '<span class="' + totalClass + ' text__caption-middle"></span>';
                }
            },
            navigation: {
                prevEl: prevElement,
                nextEl: nextElement,
            },
        });

        const textElement = container.querySelector('[data-rslider="partners-products-text-slider"]');
        if (!textElement) {
            console.log('Could not find any element with data-index-text-slider');
            return;
        }
        const textSlider = new RSlider(
            textElement,
            {
                onStart: ({ slideIn, slideFriendsIn }) => {
                    slideFriendsIn.forEach((friendIn) => {
                        friendIn.classList.add('_active');
                    })
                    slideIn.classList.add('_active');
                },
                timeline: [
                    RSlider.action({
                        // К слайдам докидывается:
                        // К появляющемуся: --rslider-first-act-in-progress: 0.2;
                        // К исчезающему: --rslider-first-act-out-progress: 0.2;
                        // easing ниже настраивается
                        name: 'next-act',
                        duration: 1000,
                        delay: 0,
                        // easeInOutCubic прогресс для "приходящего" слайда
                        // easingIn: (x) => x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2,
                        // linear прогресс для "уходящего" слайда
                        // easingOut: (x) => x,
                        onStart: ({ slideIn, slideOut, slideFriendsIn, slideFriendsOut }) => {
                            slideIn.classList.add('_active');
                            slideOut.classList.remove('_active');

                            slideIn.classList.add('_in-next-act');
                            slideOut.classList.add('_out-next-act');

                            slideFriendsIn.forEach((friendIn) => {
                                friendIn.classList.add('_active');
                                friendIn.classList.add('_in-next-act');
                            })

                            setTimeout(() => {
                                slideIn.classList.remove('_in-next-act');

                                slideFriendsIn.forEach((friendIn) => {
                                    friendIn.classList.remove('_in-next-act');
                                })
                            }, 500);

                            slideFriendsOut.forEach((friendOut) => {
                                friendOut.classList.remove('_active');
                                friendOut.classList.add('_out-next-act');
                            })
                        },
                        onEnd: ({ slideIn, slideOut, slideFriendsIn, slideFriendsOut }) => {
                            slideOut.classList.remove('_out-next-act');

                            slideFriendsOut.forEach((friendOut) => {
                                friendOut.classList.remove('_out-next-act');
                            })
                        }
                    }),
                ],
            });

        slider.on('slideNextTransitionStart', () => {
            textSlider.next();
        });

        slider.on('slidePrevTransitionStart', () => {
            textSlider.prev();
        });
    });
}

onDomReady(init);