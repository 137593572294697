export default class ScrollContent {
    constructor(list) {
        this.list = list
        this.currentItem = undefined
        this.content = [];
        this.pageYOffset = 0;
        this.startOffset = 20;
        this.init();
    }

    init() {
        this.onResize();
        this.content = this.list.querySelectorAll('[data-about-scroll-item]');
        this.addEvents();
        this.check();
    }

    addEvents() {
        const instance = this;
        window.addEventListener('resize', () => instance.onResize());

        document.addEventListener('scroll', () => {
            instance.pageYOffset = window.scrollY;
        });
    }

    onResize() {
        this.windowHeight = window.innerHeight;
    }

    // сгенерено qwen AI с небольшими поправками
    calculateScrollPercentage(item) {
        const elementHeight = item.offsetHeight; // Высота элемента
        const rect = item.getBoundingClientRect(); // Получаем позицию элемента относительно viewport

        // Вычисляем процент прокрутки элемента
        const scrolled = Math.abs(rect.top); // На сколько пикселей элемент уже "прокручен"
        const percentage = (scrolled / elementHeight) * 100;

        // Ограничиваем процент в диапазоне от 0 до 100
        return Math.min(Math.max(percentage, 0), 100);
    }

    check() {
        const windowHeight = this.windowHeight;

        if (this.pageYOffset !== this.lastPageYOffset) {
            this.content.forEach((element) => {
                const rect = element.getBoundingClientRect(); // Получаем позицию элемента относительно viewport
                if (rect.top <= 0 && rect.bottom >= 0) {
                    const elementHeight = element.offsetHeight;
                    const bounds = element.getBoundingClientRect();

                    let animate = false;

                    if (bounds.top <= this.startOffset) {
                        document.querySelector('[data-about-scroll-sidebar]')
                            .style.setProperty('--animation-progress', `${this.calculateScrollPercentage(element)}%`);
                        animate = true;
                    } else {
                        document.querySelector('[data-about-scroll-sidebar]')
                            .style.setProperty('--animation-progress', `${this.calculateScrollPercentage(element)}%`);
                        animate = false;
                    }

                    if (animate) {
                        element.classList.add('_active');
                        this.setCurrentItem(element);
                    } else {
                        element.classList.remove('_active');
                        this.currentItem = undefined;
                    }
                }
            });

            this.lastPageYOffset = this.pageYOffset;
        }

        window.requestAnimationFrame(this.check.bind(this));
    }

    setCurrentItem(element) {
        this.currentItem = element;
        document.dispatchEvent(new CustomEvent('active-scroll-item', {
            detail: {
                item: this.currentItem
            }
        }));
    }
}
